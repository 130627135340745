<style>
body {
  font-family: "Noto Sans KR", sans-serif;
  text-align: center;
}
article {
  background: #fff;
}
tbody {
  text-align: center;
}
tbody tr {
  text-align: center;
}
.table td {
  padding: 20px 30px !important;
}
.time tr {
  border: none;
}
.time td {
  padding: 2px 2px !important;
}
.style59 {
  background: #ffcc00;
  border-radius: 20px;
  width: 120px;
}
.btn {
  display: inline-block !important;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_box {
  margin-top: 40px;
}
.btn-danger {
  margin: 0px 20px 0px -10px;
}
.style38 > span {
  font-size: 20px;
}
.table_question {
  margin-top: 60px;
}
</style>
<template>
  <div
    style="
      background: #252525;
      height: 120px;
      line-height: 10px;
      border-bottom: 1px solid #ccc;
    "
  >
    <div
      style="
        width: 1000px;
        margin: 0 auto;
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        color: #013b54;
        padding-top: 0px;
      "
    >
      <img src="https://bizstudy.useschool.co.kr/img/header/test_title.png" />
    </div>
  </div>

  <div
    class="container_leveltest"
    style="background: #fff; width: 70%; padding: 30px; margin-top: 20px"
  >
    <table class="table">
      <tbody>
        <template v-if="strState === 'Start'">
          <!--테이블로 만든 <div class="content">
            <table class="table table-striped" width="80%" border="0">
               <tbody>
                <tr>
                  <td colspan="2" class="first_td">Phonics의 테스트는?</td>
               </tr>
                <tr>
                  <td>Listening Test(듣기 영역)</td>
                  <td class="red">10문항</td>
               </tr>
                <tr>
                  <td>Reading Test(읽기 영역)</td>
                  <td class="red">15문항</td>
               </tr>
               <tr>
                  <td class="last">Total</td>
                  <td class="last red">25문항</td>
               </tr>
               </tbody>
            </table>

            <table class="table table-striped" width="80%" border="0">
               <tbody>
                <tr>
                  <td class="first_td">시험 중 유의사항</td>
               </tr>
                <tr>
                  <td>문제를 풀 수 있는 시간은 총 40분입니다.</td>
               </tr>
                <tr>
                  <td>시험 도중 화면을 끄면 시험이 저장되지 않습니다.</td>
               </tr>
               <tr>
                  <td class="last">모든 문제를 풀 때까지 시험을 종료하지 않도록 주의하십시오.</td>
               </tr>
               </tbody>
            </table>
        </div>     -->

          <table class="table_question">
            <tbody>
              <tr>
                <td v-if="$route.params.level">
                  <!--<img :src="`https://useschool.co.kr/PT/LT/images_use/0start_${$route.params.level}.gif`">-->
                  <img
                    :src="`https://bizstudy.useschool.co.kr/img/leveltest/0start_${$route.params.level}.gif`"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div style="text-align: center">
            <button
              v-on:click="goLStep()"
              class="btn btn-primary"
              style="font-size: 24px; padding: 4px 40px"
            >
              START
            </button>
          </div>
        </template>

        <template v-else-if="strState === 'LStart' && intque === 0">
          <!--테이블로 만든
               <table class="table" width="90%" border="0">
                <tbody>
                  <tr>
                    <td class="first_td"> Listening 듣기영역</td>
                  </tr>
                  <tr>
                    <td>지금부터 Listening Test를 시작하겠습니다.</td>
                  </tr>
                  <tr>
                    <td>화면 상단의 제한시간(20분)을 유의해 주시기 바랍니다.</td>
                    </tr>
                </tbody>
              </table>

            <table class="table" width="90%" border="0">
                <tbody>
                  <tr>
                    <td class="first_td"> 시험 중 유의사항</td>
                  </tr>
                  <tr>
                    <td>듣기 테스트를 위하여 '헤드셋' 또는 '스피커'를 확인하기 바랍니다.</td>
                  </tr>
                </tbody>
              </table> -->

          <div class="row">
            <div style="width: 600px; margin: 0 auto">
              <div class="main-card mb-3 card" style="border: none">
                <div class="card-body" style="border: none; text-align: center">
                  <img
                    src="https://bizstudy.useschool.co.kr/img/listening_test.png"
                    alt="듣기영역 시작합니다"
                  />

                  <p
                    style="
                      font-size: 20px;
                      display: inline-block;
                      padding: 20px;
                      color: #2e2e2e;
                    "
                  >
                    지금부터 Listening Test를 시작하겠습니다.<br />
                    화면 상단의
                    <span
                      style="color: #d10003; font-weight: bold; font-size: 20px"
                      >제한시간(20분)</span
                    >을 유의해 주시기바랍니다.
                  </p>

                  <p
                    style="
                      font-size: 20px;
                      display: inline-block;
                      padding: 20px 30px 30px 30px;
                      color: #2e2e2e;
                      box-shadow: 2px 4px 18px rgba(0, 0, 0, 0.1);
                      border-radius: 18px;
                      margin-bottom: 30px;
                    "
                  >
                    <span
                      style="
                        font-weight: bold;
                        font-size: 20px;
                        display: inline-block;
                        margin-bottom: 10px;
                      "
                      >시험중 유의사항</span
                    ><br />
                    듣기 테스트를 위하여 '헤드셋' 또는 '스피커'를 확인하기
                    바랍니다.<br />
                    다시 듣기는 한번만 가능합니다.
                  </p>
                  <div style="text-align: center">
                    <button
                      v-on:click="nextLStart(intque)"
                      class="btn btn-primary"
                    >
                      NEXT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-else-if="strState === 'LEnd'">
          <table class="table_question" border="0">
            <tbody>
              <tr>
                <td>
                  <img
                    src="https://bizstudy.useschool.co.kr/img/listening_test.png"
                    alt="듣기영역 시작합니다"
                  />

                  <p
                    style="
                      font-size: 20px;
                      display: block;
                      padding: 30px 0px;
                      color: #2e2e2e;
                    "
                  >
                    Listening Test가 끝났습니다.<br />
                    <span
                      style="color: #d10003; font-weight: bold; font-size: 20px"
                      >Reading Test</span
                    >로 넘어가시려면
                    <span
                      style="color: #337ab7; font-weight: bold; font-size: 20px"
                      >Next</span
                    >
                    버튼을 눌러주세요.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <button v-on:click="nextLEnd()" class="btn btn-primary">NEXT</button>
        </template>

        <template v-else-if="strState === 'RStart'">
          <table class="table_question" border="0">
            <tbody>
              <tr>
                <td>
                  <img src="https://mypathkr.com/img/reading_start.png" /><br />
                  <p
                    style="
                      font-size: 20px;
                      display: inline-block;
                      padding: 20px;
                      color: #2e2e2e;
                    "
                  >
                    지금부터 Reading Test를 시작하겠습니다.<br />
                    화면 상단의
                    <span
                      style="color: #d10003; font-weight: bold; font-size: 20px"
                      >제한시간(20분)</span
                    >을 유의해 주시기바랍니다.
                  </p>
                  <br />

                  <p
                    style="
                      font-size: 20px;
                      display: inline-block;
                      padding: 20px 30px 30px 30px;
                      color: #2e2e2e;
                      box-shadow: 2px 4px 18px rgba(0, 0, 0, 0.1);
                      border-radius: 18px;
                      margin-bottom: 30px;
                      margin-top: 10px;
                    "
                  >
                    <span
                      style="
                        font-weight: bold;
                        font-size: 20px;
                        display: inline-block;
                        margin-bottom: 10px;
                      "
                      >시험중 유의사항</span
                    ><br />
                    준비가 되면 'Next' 버튼을 누르십시오.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <button v-on:click="nextRStart(intque)" class="btn btn-primary">
            NEXT
          </button>
        </template>
        <template v-else-if="strState === 'REnd'">
          <table class="table_question">
            <tbody>
              <tr>
                <td>
                  <img src="https://useschool.co.kr/PT/LT/images/Turnimg.png" />
                </td>
              </tr>
              <tr>
                <td>
                  <p style="font-size: 24px; font-weight: bold">
                    이제 모든 시험을 마쳤습니다.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>"Turn in”</strong> 버튼을 누르면 시험 결과를 확인할 수
                  있습니다.
                </td>
              </tr>
            </tbody>
          </table>
          <button v-on:click="nextEnd()" class="btn btn-primary">
            Turn in
          </button>
        </template>
        <template v-else-if="strState === 'sEnd'">
          <body style="background: #ededed">
            <div
              style="
                margin: 0 auto;
                padding-bottom: 20px;
                margin-top: 40px;
                background: #fff;
              "
            >
              <div style="text-align: center; margin: 0 auto; margin-top: 50px">
                <img
                  src="https://useschool.co.kr/img/logo_childumypath.svg"
                  style="width: 200px"
                />
              </div>
              <p
                style="
                  font-size: 50px;
                  text-align: center;
                  font-family: 'Roboto Serif', sans-serif;
                  margin: 10px 0;
                  font-weight: bold;
                "
              >
                Placement Test
              </p>
              <p style="text-align: center">
                <span style="font-weight: bold"> {{ $root.user.name }}</span
                >님의 시험결과는 다음과 같습니다.
              </p>

              <div style="width: 600px; margin: 0 auto; position: relative">
                <div style="position: absolute; top: -15px; left: 15px">
                  <img src="https://useschool.co.kr/img/star.png" />
                </div>
                <div
                  style="
                    width: 600px;
                    height: 90px;
                    background: #eeeeee;
                    text-align: center;
                    margin: 20px auto;
                    display: flex;
                  "
                >
                  <div style="width: 280px; height: 90px; padding-top: 20px">
                    <span style="font-size: 34px; vertical-align: middle"
                      ><span
                        v-if="
                          $route.params.level === '1' ||
                          $route.params.level === '2' ||
                          $route.params.level === '3' ||
                          $route.params.level === '4' ||
                          $route.params.level === '5' ||
                          $route.params.level === '6' ||
                          $route.params.level === '7' ||
                          $route.params.level === '8'
                        "
                        >Grade </span
                      >{{ $route.params.level }}</span
                    >
                  </div>
                  <div style="width: 40px; margin-top: 30px">
                    <img src="https://useschool.co.kr/img/arrow.png" />
                  </div>
                  <div style="width: 280px; height: 90px; padding-top: 20px">
                    <span
                      style="font-size: 34px; color: #253b79; font-weight: bold"
                      >{{ result.t_total }}</span
                    ><span style="font-size: 34px">점</span>
                  </div>
                </div>
              </div>

              <table
                width="600"
                border="0"
                align="center"
                cellpadding="0"
                cellspacing="0"
                style="margin: 40px auto; background: #f8f8f8"
              >
                <tr>
                  <td
                    colspan="2"
                    width="150"
                    height="30"
                    align="center"
                    class="style36Eng"
                    style="
                      background: rgb(37, 59, 121);
                      color: #fff;
                      font-size: 24px;
                      font-weight: bold;
                    "
                  >
                    SCORE
                  </td>
                </tr>

                <tr bgcolor="" style="border-bottom: 1px solid #ededed">
                  <td
                    height="40"
                    align="center"
                    class="style36Eng"
                    style="
                      color: rgb(37, 59, 121);
                      border-bottom: 1px solid #e3e3e3;
                    "
                  >
                    0 ~ 59
                  </td>
                  <td
                    class="style19"
                    style="color: #333; border-bottom: 1px solid #e3e3e3"
                  >
                    한 학년 낮은 시험을 다시 보고 적정레벨을 찾으세요.
                  </td>
                </tr>
                <tr>
                  <td
                    height="40"
                    align="center"
                    class="style36Eng"
                    style="
                      color: rgb(37, 59, 121);
                      border-bottom: 1px solid #e3e3e3;
                    "
                  >
                    60 ~ 89
                  </td>
                  <td
                    class="style19"
                    style="color: #333; border-bottom: 1px solid #e3e3e3"
                  >
                    시험 본 해당 학년의 레벨이 적합합니다.
                  </td>
                </tr>
                <tr>
                  <td
                    height="40"
                    align="center"
                    class="style36Eng"
                    style="color: rgb(37, 59, 121)"
                  >
                    90 ~ 100
                  </td>
                  <td
                    class="style19"
                    style="color: #333; border-bottom: 1px solid #e3e3e3"
                  >
                    한 학년 높은 시험을 다시 보고 적정레벨을 찾으세요.
                  </td>
                </tr>
              </table>

              <table
                width="600"
                border="0"
                align="center"
                cellpadding="0"
                cellspacing="10"
                bgcolor="#f6f6f6"
                style="
                  padding: 20px 0;
                  margin: 20px auto;
                  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.1);
                  border-radius: 18px;
                "
              >
                <tr style="border-top: 0px">
                  <td
                    width="30"
                    align="right"
                    valign="top"
                    style="font-weight: bold"
                  >
                    -
                  </td>
                  <td width="555" style="color: #333; text-align: left">
                    본 테스트는 미국교과 학년의 Language Arts 영역을 평가하여<br />학습자가
                    어느 레벨부터 학습하는 것이 적정한 지를 안내하는데<br />
                    목적을 두고 있습니다.
                  </td>
                </tr>
                <tr>
                  <td align="right" valign="top" style="font-weight: bold">
                    -
                  </td>
                  <td style="color: #333; text-align: left">
                    테스트 결과, 실제 본인의 학년보다 더 높은 레벨이 나왔다면<br />
                    실제 본인 학년의 학습과정을 속성으로라도 먼저 학습하여<br />
                    Language Arts 뿐만 아니라, Math, Science, Social Studies<br />
                    등의 풍부한 어휘와 다양한 배경지식을 습득할 것을 권장합니다.
                  </td>
                </tr>
                <tr>
                  <td align="right" valign="top" style="font-weight: bold">
                    -
                  </td>
                  <td style="color: #333; text-align: left">
                    Phonics 레벨에서 89점 이하일 경우, 마이유즈 Phonics 학습을
                    권장합니다.
                  </td>
                </tr>
              </table>
            </div>
          </body>
        </template>
        <template v-else>
          <template v-if="intque && questions[intque - 1].strQType === 'l1'">
            <table class="time" style="float: right">
              <tr>
                <td width="230">
                  <table
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                      background: #efefef;
                      border-radius: 50px;
                      height: 40px;
                      line-height: 40px;
                      margin: 0 auto;
                    "
                  >
                    <tr>
                      <td width="213" align="center" class="style59">
                        <img
                          src="https://childu.co.kr/img/test_clock.png"
                          style="
                            width: 26px;
                            position: relative;
                            top: -2px;
                            margin-right: 8px;
                          "
                        />
                        <span id="spanClock">{{ resTimeData }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="3" class="style38" style="text-align: center">
                    {{ questions[intque - 1].intQNo }}.
                    <span v-html="questions[intque - 1].strQuestion"></span>
                  </td>
                </tr>
                <tr>
                  <td class="answer" v-for="(episode, i) in episodes" :key="i">
                    <img
                      :src="episode.thumbnail"
                      v-on:click="
                        SelEx(intque, episode.strValue, episode.num, 3)
                      "
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].A_file
                      }.jpg`"
                      style="border: 1px solid #ccc"
                    />
                  </td>
                  <td>
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].B_file
                      }.jpg`"
                      style="border: 1px solid #ccc"
                    />
                  </td>
                  <td>
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].C_file
                      }.jpg`"
                      style="border: 1px solid #ccc"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button
                class="btn btn-danger"
                v-on:click="
                  playAudio(
                    `https://www.useschool.co.kr/PT/LT/data/${
                      questions[intque - 1].Q_mpfile
                    }.mp3`
                  )
                "
              >
                Again
              </button>
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">
                Next
              </button>
            </div>
          </template>
          <template v-if="intque && questions[intque - 1].strQType === 'l3'">
            <table class="time" style="float: right">
              <tr>
                <td width="230">
                  <table
                    width="0"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                      background: #efefef;
                      border-radius: 50px;
                      height: 40px;
                      line-height: 40px;
                      margin: 0 auto;
                    "
                  >
                    <tr>
                      <td width="213" align="center" class="style59">
                        <img
                          src="https://childu.co.kr/img/test_clock.png"
                          style="
                            width: 26px;
                            position: relative;
                            top: -2px;
                            margin-right: 8px;
                          "
                        />
                        <span id="spanClock">{{ resTimeData }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question" style="width: 600px">
              <tbody>
                <tr>
                  <td colspan="3" class="style38" style="text-align: center">
                    {{ questions[intque - 1].intQNo }}.
                    <span v-html="questions[intque - 1].strQuestion"></span>
                  </td>
                </tr>

                <tr>
                  <td width="45" class="answer">
                    <img
                      :src="`${episodes[0].thumbnail}`"
                      :id="`img_ex${episodes[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelEx(intque, episodes[0].strValue, episodes[0].num, 3)
                      "
                    />
                  </td>
                  <td width="605" style="text-align: left">
                    <img
                      v-on:click="
                        playAudio(
                          `https://www.useschool.co.kr/PT/LT/data/${
                            questions[intque - 1].A_mpfile
                          }.mp3`
                        )
                      "
                      v-if="questions[intque - 1].A_mpfile"
                      src="https://www.useschool.co.kr/PT/LT/images/btn_Sound.gif"
                      style="cursor: pointer"
                    />
                  </td>
                </tr>
                <tr>
                  <td width="45">
                    <img
                      :src="`${episodes[1].thumbnail}`"
                      :id="`img_ex${episodes[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelEx(intque, episodes[1].strValue, episodes[1].num, 3)
                      "
                    />
                  </td>
                  <td width="605" style="text-align: left">
                    <img
                      v-on:click="
                        playAudio(
                          `https://www.useschool.co.kr/PT/LT/data/${
                            questions[intque - 1].B_mpfile
                          }.mp3`
                        )
                      "
                      v-if="questions[intque - 1].B_mpfile"
                      src="https://www.useschool.co.kr/PT/LT/images/btn_Sound.gif "
                      style="cursor: pointer"
                    />
                  </td>
                </tr>
                <tr>
                  <td width="45">
                    <img
                      :src="`${episodes[2].thumbnail}`"
                      :id="`img_ex${episodes[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelEx(intque, episodes[2].strValue, episodes[2].num, 3)
                      "
                    />
                  </td>
                  <td width="605" style="text-align: left">
                    <img
                      v-on:click="
                        playAudio(
                          `https://www.useschool.co.kr/PT/LT/data/${
                            questions[intque - 1].C_mpfile
                          }.mp3`
                        )
                      "
                      v-if="questions[intque - 1].C_mpfile"
                      src="https://www.useschool.co.kr/PT/LT/images/btn_Sound.gif"
                      style="cursor: pointer"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button
                v-on:click="
                  playAudio(
                    `https://www.useschool.co.kr/PT/LT/data/${
                      questions[intque - 1].Q_mpfile
                    }.mp3`
                  )
                "
                class="btn btn-danger"
              >
                Again
              </button>
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">
                Next
              </button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'l4'">
            <table class="time" style="float: right">
              <tr>
                <td width="230">
                  <table
                    width="0"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                      background: #efefef;
                      border-radius: 50px;
                      height: 40px;
                      line-height: 40px;
                      margin: 0 auto;
                    "
                  >
                    <tr>
                      <td width="213" align="center" class="style59">
                        <img
                          src="https://childu.co.kr/img/test_clock.png"
                          style="
                            width: 26px;
                            position: relative;
                            top: -2px;
                            margin-right: 8px;
                          "
                        />
                        <span id="spanClock">{{ resTimeData }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="3" class="style38" style="text-align: center">
                    {{ questions[intque - 1].intQNo }}.
                    <span v-html="questions[intque - 1].strQuestion"></span>
                  </td>
                </tr>
                <tr>
                  <td width="50" class="answer">
                    <img
                      :src="`${episodes[0].thumbnail}`"
                      :id="`img_ex${episodes[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelEx(intque, episodes[0].strValue, episodes[0].num, 3)
                      "
                    />
                  </td>
                  <td>
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].A_file
                      }.jpg`"
                    />
                  </td>
                  <td width="50">
                    <img
                      :src="`${episodes[1].thumbnail}`"
                      :id="`img_ex${episodes[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelEx(intque, episodes[1].strValue, episodes[1].num, 3)
                      "
                    />
                  </td>
                  <td>
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].B_file
                      }.jpg`"
                    />
                  </td>
                  <td width="50">
                    <img
                      :src="`${episodes[2].thumbnail}`"
                      :id="`img_ex${episodes[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelEx(intque, episodes[2].strValue, episodes[2].num, 3)
                      "
                    />
                  </td>
                  <td>
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].C_file
                      }.jpg`"
                    />
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>
                    <img
                      v-on:click="
                        playAudio(
                          `https://www.useschool.co.kr/PT/LT/data/${
                            questions[intque - 1].A_mpfile
                          }.mp3`
                        )
                      "
                      v-if="questions[intque - 1].A_mpfile"
                      src="https://www.useschool.co.kr/PT/LT/images/btn_Sound.gif"
                      style="cursor: pointer"
                    />
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    <img
                      v-on:click="
                        playAudio(
                          `https://www.useschool.co.kr/PT/LT/data/${
                            questions[intque - 1].B_mpfile
                          }.mp3`
                        )
                      "
                      v-if="questions[intque - 1].B_mpfile"
                      src="https://www.useschool.co.kr/PT/LT/images/btn_Sound.gif"
                      style="cursor: pointer"
                    />
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    <img
                      v-on:click="
                        playAudio(
                          `https://www.useschool.co.kr/PT/LT/data/${
                            questions[intque - 1].C_mpfile
                          }.mp3`
                        )
                      "
                      v-if="questions[intque - 1].C_mpfile"
                      src="https://www.useschool.co.kr/PT/LT/images/btn_Sound.gif"
                      style="cursor: pointer"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button
                v-on:click="
                  playAudio(
                    `https://www.useschool.co.kr/PT/LT/data/${
                      questions[intque - 1].Q_mpfile
                    }.mp3`
                  )
                "
                class="btn btn-danger"
              >
                Again
              </button>
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">
                Next
              </button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'l6'">
            <table class="time" style="float: right">
              <tr>
                <td width="230">
                  <table
                    width="0"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                      background: #efefef;
                      border-radius: 50px;
                      height: 40px;
                      line-height: 40px;
                      margin: 0 auto;
                    "
                  >
                    <tr>
                      <td width="213" align="center" class="style59">
                        <img
                          src="https://childu.co.kr/img/test_clock.png"
                          style="
                            width: 26px;
                            position: relative;
                            top: -2px;
                            margin-right: 8px;
                          "
                        />
                        <span id="spanClock">{{ resTimeData }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question">
              <tbody>
                <tr style="text-align: center">
                  <td colspan="3" class="style38">
                    {{ questions[intque - 1].intQNo }}.
                    <span v-html="questions[intque - 1].strQuestion"></span>
                  </td>
                </tr>
                <tr>
                  <td class="answer" v-for="(episode, i) in episodes" :key="i">
                    <img
                      :src="episode.thumbnail"
                      :id="`img_ex${episode.num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelEx(intque, episode.strValue, episode.num, 3)
                      "
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].A_file
                      }.jpg`"
                    />
                  </td>
                  <td>
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].B_file
                      }.jpg`"
                    />
                  </td>
                  <td>
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].C_file
                      }.jpg`"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      v-if="questions[intque - 1].A_mpfile"
                      v-on:click="
                        playAudio(
                          `https://www.useschool.co.kr/PT/LT/data/${
                            questions[intque - 1].A_mpfile
                          }.mp3`
                        )
                      "
                      src="https://www.useschool.co.kr/PT/LT/images/btn_Sound.gif"
                    />
                  </td>
                  <td>
                    <img
                      v-if="questions[intque - 1].B_mpfile"
                      v-on:click="
                        playAudio(
                          `https://www.useschool.co.kr/PT/LT/data/${
                            questions[intque - 1].B_mpfile
                          }.mp3`
                        )
                      "
                      src="https://www.useschool.co.kr/PT/LT/images/btn_Sound.gif"
                    />
                  </td>
                  <td>
                    <img
                      v-if="questions[intque - 1].C_mpfile"
                      v-on:click="
                        playAudio(
                          `https://www.useschool.co.kr/PT/LT/data/${
                            questions[intque - 1].C_mpfile
                          }.mp3`
                        )
                      "
                      src="https://www.useschool.co.kr/PT/LT/images/btn_Sound.gif"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button
                v-on:click="
                  playAudio(
                    `https://www.useschool.co.kr/PT/LT/data/${
                      questions[intque - 1].Q_mpfile
                    }.mp3`
                  )
                "
                class="btn btn-danger"
              >
                Again
              </button>
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">
                Next
              </button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'l7'">
            <table class="time" style="float: right">
              <tr>
                <td width="230">
                  <table
                    width="0"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                      background: #efefef;
                      border-radius: 50px;
                      height: 40px;
                      line-height: 40px;
                      margin: 0 auto;
                    "
                  >
                    <tr>
                      <td width="213" align="center" class="style59">
                        <img
                          src="https://childu.co.kr/img/test_clock.png"
                          style="
                            width: 26px;
                            position: relative;
                            top: -2px;
                            margin-right: 8px;
                          "
                        />
                        <span id="spanClock">{{ resTimeData }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    {{ questions[intque - 1].intQNo }}.
                    <span v-html="questions[intque - 1].strQuestion"></span>
                  </td>
                </tr>
                <tr>
                  <td class="answer">
                    <img
                      :src="`${episodes[0].thumbnail}`"
                      :id="`img_ex${episodes[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelEx(intque, episodes[0].strValue, episodes[0].num, 3)
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].A"></span>
                  </td>
                </tr>
                <tr>
                  <td class="answer">
                    <img
                      :src="`${episodes[1].thumbnail}`"
                      :id="`img_ex${episodes[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelEx(intque, episodes[1].strValue, episodes[1].num, 3)
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].B"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodes[2].thumbnail}`"
                      :id="`img_ex${episodes[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelEx(intque, episodes[2].strValue, episodes[2].num, 3)
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].C"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button
                v-on:click="
                  playAudio(
                    `https://www.useschool.co.kr/PT/LT/data/${
                      questions[intque - 1].Q_mpfile
                    }.mp3`
                  )
                "
                class="btn btn-danger"
              >
                Again
              </button>
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">
                Next
              </button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'r1'">
            <table class="time" style="float: right">
              <tr>
                <td width="230">
                  <table
                    width="0"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                      background: #efefef;
                      border-radius: 50px;
                      height: 40px;
                      line-height: 40px;
                      margin: 0 auto;
                    "
                  >
                    <tr>
                      <td width="213" align="center" class="style59">
                        <img
                          src="https://childu.co.kr/img/test_clock.png"
                          style="
                            width: 26px;
                            position: relative;
                            top: -2px;
                            margin-right: 8px;
                          "
                        />
                        <span id="spanClock">{{ resTimeData }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    {{ questions[intque - 1].intQNo }}.
                    <span v-html="questions[intque - 1].strQuestion"></span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="style37">
                    <span v-html="questions[intque - 1].strQuestion2"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[0].thumbnail}`"
                      :id="`img_ex${episodess[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[0].strValue,
                          episodess[0].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].A"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[1].thumbnail}`"
                      :id="`img_ex${episodess[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[1].strValue,
                          episodess[1].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].B"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[2].thumbnail}`"
                      :id="`img_ex${episodess[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[2].strValue,
                          episodess[2].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].C"></span>
                  </td>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td>
                    <img
                      :src="`${episodess[3].thumbnail}`"
                      :id="`img_ex${episodess[3].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[3].strValue,
                          episodess[3].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].D"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">
                Next
              </button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'r2'">
            <table class="time" style="float: right">
              <tr>
                <td width="230">
                  <table
                    width="0"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                      background: #efefef;
                      border-radius: 50px;
                      height: 40px;
                      line-height: 40px;
                      margin: 0 auto;
                    "
                  >
                    <tr>
                      <td width="213" align="center" class="style59">
                        <img
                          src="https://childu.co.kr/img/test_clock.png"
                          style="
                            width: 26px;
                            position: relative;
                            top: -2px;
                            margin-right: 8px;
                          "
                        />
                        <span id="spanClock">{{ resTimeData }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    {{ questions[intque - 1].intQNo }}.
                    <span v-html="questions[intque - 1].strQuestion"></span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="style37">
                    <span v-html="questions[intque - 1].strQuestion2"></span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="style38">
                    <span v-html="questions[intque - 1].strQuestion3"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[0].thumbnail}`"
                      :id="`img_ex${episodess[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[0].strValue,
                          episodess[0].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].A"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[1].thumbnail}`"
                      :id="`img_ex${episodess[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[0].strValue,
                          episodess[1].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].B"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[2].thumbnail}`"
                      :id="`img_ex${episodess[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[0].strValue,
                          episodess[2].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].C"></span>
                  </td>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td>
                    <img
                      :src="`${episodess[3].thumbnail}`"
                      :id="`img_ex${episodess[3].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[0].strValue,
                          episodess[3].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].D"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">
                Next
              </button>
            </div>
          </template>
          <template v-if="intque && questions[intque - 1].strQType === 'r3'">
            <table class="time" style="float: right">
              <tr>
                <td width="230">
                  <table
                    width="0"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                      background: #efefef;
                      border-radius: 50px;
                      height: 40px;
                      line-height: 40px;
                      margin: 0 auto;
                    "
                  >
                    <tr>
                      <td width="213" align="center" class="style59">
                        <img
                          src="https://childu.co.kr/img/test_clock.png"
                          style="
                            width: 26px;
                            position: relative;
                            top: -2px;
                            margin-right: 8px;
                          "
                        />
                        <span id="spanClock">{{ resTimeData }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    {{ questions[intque - 1].intQNo }}.
                    <span v-html="questions[intque - 1].strQuestion"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[0].thumbnail}`"
                      :id="`img_ex${episodess[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[0].strValue,
                          episodess[0].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].A"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[1].thumbnail}`"
                      :id="`img_ex${episodess[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[1].strValue,
                          episodess[1].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].B"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[2].thumbnail}`"
                      :id="`img_ex${episodess[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[2].strValue,
                          episodess[2].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].C"></span>
                  </td>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td>
                    <img
                      :src="`${episodess[3].thumbnail}`"
                      :id="`img_ex${episodess[3].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[3].strValue,
                          episodess[3].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].D"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">
                Next
              </button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'r4'">
            <table class="time" style="float: right">
              <tr>
                <td width="230">
                  <table
                    width="0"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                      background: #efefef;
                      border-radius: 50px;
                      height: 40px;
                      line-height: 40px;
                      margin: 0 auto;
                    "
                  >
                    <tr>
                      <td width="213" align="center" class="style59">
                        <img
                          src="https://childu.co.kr/img/test_clock.png"
                          style="
                            width: 26px;
                            position: relative;
                            top: -2px;
                            margin-right: 8px;
                          "
                        />
                        <span id="spanClock">{{ resTimeData }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question" style="width: 50%">
              <tbody>
                <tr>
                  <td colspan="4" class="style38" style="text-align: center">
                    {{ questions[intque - 1].intQNo }}.
                    <span v-html="questions[intque - 1].strQuestion"></span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].Q_file
                      }.jpg`"
                    />
                  </td>
                </tr>
                <tr>
                  <td width="10">
                    <img
                      :src="`${episodess[0].thumbnail}`"
                      :id="`img_ex${episodess[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[0].strValue,
                          episodess[0].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td width="90" style="text-align: left" class="style38">
                    <span v-html="questions[intque - 1].A"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[1].thumbnail}`"
                      :id="`img_ex${episodess[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[1].strValue,
                          episodess[1].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td width="50" style="text-align: left" class="style38">
                    <span v-html="questions[intque - 1].B"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[2].thumbnail}`"
                      :id="`img_ex${episodess[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[2].strValue,
                          episodess[2].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td width="50" style="text-align: left" class="style38">
                    <span v-html="questions[intque - 1].C"></span>
                  </td>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td>
                    <img
                      :src="`${episodess[3].thumbnail}`"
                      :id="`img_ex${episodess[3].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[3].strValue,
                          episodess[3].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td width="50" style="text-align: left" class="style38">
                    <span v-html="questions[intque - 1].D"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">
                Next
              </button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'r5'">
            <table class="time" style="float: right">
              <tr>
                <td width="0">
                  <table
                    width="213"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                      background: #efefef;
                      border-radius: 50px;
                      height: 40px;
                      line-height: 40px;
                      margin: 0 auto;
                    "
                  >
                    <tr>
                      <td width="213" align="center" class="style59">
                        <img
                          src="https://childu.co.kr/img/test_clock.png"
                          style="
                            width: 26px;
                            position: relative;
                            top: -2px;
                            margin-right: 8px;
                          "
                        />
                        <span id="spanClock">{{ resTimeData }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question" style="width: 900px">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    {{ questions[intque - 1].intQNo }}.
                    <span v-html="questions[intque - 1].strQuestion"></span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="style38">
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].Q_file
                      }.jpg`"
                    />
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="style38">
                    <span v-html="questions[intque - 1].strQuestion2"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[0].thumbnail}`"
                      :id="`img_ex${episodess[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[0].strValue,
                          episodess[0].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].A"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[1].thumbnail}`"
                      :id="`img_ex${episodess[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[1].strValue,
                          episodess[1].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].B"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[2].thumbnail}`"
                      :id="`img_ex${episodess[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[2].strValue,
                          episodess[2].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].C"></span>
                  </td>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td>
                    <img
                      :src="`${episodess[3].thumbnail}`"
                      :id="`img_ex${episodess[3].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[3].strValue,
                          episodess[3].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].D"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">
                Next
              </button>
            </div>
          </template>
          <template v-if="intque && questions[intque - 1].strQType === 'r6'">
            <table class="time" style="float: right">
              <tr>
                <td width="230">
                  <table
                    width="0"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                      background: #efefef;
                      border-radius: 50px;
                      height: 40px;
                      line-height: 40px;
                      margin: 0 auto;
                    "
                  >
                    <tr>
                      <td width="213" align="center" class="style59">
                        <img
                          src="https://childu.co.kr/img/test_clock.png"
                          style="
                            width: 26px;
                            position: relative;
                            top: -2px;
                            margin-right: 8px;
                          "
                        />
                        <span id="spanClock">{{ resTimeData }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    {{ questions[intque - 1].intQNo }}.
                    <span v-html="questions[intque - 1].strQuestion"></span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].Q_file
                      }.jpg`"
                    />
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="style37">
                    <span v-html="questions[intque - 1].strQuestion2"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[0].thumbnail}`"
                      :id="`img_ex${episodess[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[0].strValue,
                          episodess[0].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].A"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[1].thumbnail}`"
                      :id="`img_ex${episodess[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[1].strValue,
                          episodess[1].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].B"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[2].thumbnail}`"
                      :id="`img_ex${episodess[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[2].strValue,
                          episodess[2].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].C"></span>
                  </td>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td>
                    <img
                      :src="`${episodess[3].thumbnail}`"
                      :id="`img_ex${episodess[3].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[3].strValue,
                          episodess[3].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].D"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">
                Next
              </button>
            </div>
          </template>
          <template v-if="intque && questions[intque - 1].strQType === 'r7'">
            <table class="time" style="float: right">
              <tr>
                <td width="230">
                  <table
                    width="0"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                      background: #efefef;
                      border-radius: 50px;
                      height: 40px;
                      line-height: 40px;
                      margin: 0 auto;
                    "
                  >
                    <tr>
                      <td width="213" align="center" class="style59">
                        <img
                          src="https://childu.co.kr/img/test_clock.png"
                          style="
                            width: 26px;
                            position: relative;
                            top: -2px;
                            margin-right: 8px;
                          "
                        />
                        <span id="spanClock">{{ resTimeData }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    {{ questions[intque - 1].intQNo }}.
                    <span v-html="questions[intque - 1].strQuestion"></span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].Q_file
                      }.jpg`"
                    />
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="style37">
                    <span v-html="questions[intque - 1].strQuestion2"></span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="style37">
                    <span v-html="questions[intque - 1].strQuestion3"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[0].thumbnail}`"
                      :id="`img_ex${episodess[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[0].strValue,
                          episodess[0].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].A"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[1].thumbnail}`"
                      :id="`img_ex${episodess[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[1].strValue,
                          episodess[1].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].B"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[2].thumbnail}`"
                      :id="`img_ex${episodess[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[2].strValue,
                          episodess[2].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].C"></span>
                  </td>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td>
                    <img
                      :src="`${episodess[3].thumbnail}`"
                      :id="`img_ex${episodess[3].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[3].strValue,
                          episodess[3].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].D"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">
                Next
              </button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'r8'">
            <table class="time" style="float: right">
              <tr>
                <td width="230">
                  <table
                    width="0"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                      background: #efefef;
                      border-radius: 50px;
                      height: 40px;
                      line-height: 40px;
                      margin: 0 auto;
                    "
                  >
                    <tr>
                      <td width="213" align="center" class="style59">
                        <img
                          src="https://childu.co.kr/img/test_clock.png"
                          style="
                            width: 26px;
                            position: relative;
                            top: -2px;
                            margin-right: 8px;
                          "
                        />
                        <span id="spanClock">{{ resTimeData }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question" style="width: 900px">
              <tbody>
                <tr>
                  <td colspan="4" class="style38" style="text-align: center">
                    {{ questions[intque - 1].intQNo }}.
                    <span v-html="questions[intque - 1].strQuestion"></span>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" class="style38">
                    <span v-html="questions[intque - 1].strQuestion2"></span>
                  </td>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt === '3'">
                  <td v-for="(episode, i) in episodes" :key="i">
                    <img
                      :src="episode.thumbnail"
                      :id="`img_ex${episode.num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelEx(intque, episode.strValue, episode.num, 3)
                      "
                    />
                  </td>
                </tr>
                <tr v-else>
                  <td v-for="(episode, i) in episodes" :key="i">
                    <img
                      :src="episode.thumbnail"
                      :id="`img_ex${episode.num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(intque, episode.strValue, episode.num, 4)
                      "
                    />
                  </td>
                </tr>
                <tr>
                  <td width="300">
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].A_file
                      }.jpg`"
                    />
                  </td>
                  <td width="300">
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].B_file
                      }.jpg`"
                    />
                  </td>
                  <td width="300">
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].C_file
                      }.jpg`"
                    />
                  </td>
                  <td v-if="questions[intque - 1].intEcnt != '3'">
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].D_file
                      }.jpg`"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">
                Next
              </button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'r9'">
            <table class="time" style="float: right">
              <tr>
                <td width="230">
                  <table
                    width="0"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                      background: #efefef;
                      border-radius: 50px;
                      height: 40px;
                      line-height: 40px;
                      margin: 0 auto;
                    "
                  >
                    <tr>
                      <td width="213" align="center" class="style59">
                        <img
                          src="https://childu.co.kr/img/test_clock.png"
                          style="
                            width: 26px;
                            position: relative;
                            top: -2px;
                            margin-right: 8px;
                          "
                        />
                        <span id="spanClock">{{ resTimeData }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    <span v-html="questions[intque - 1].strQuestion"></span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="style37">
                    <span v-html="questions[intque - 1].strQuestion2"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    {{ questions[intque - 1].intQNo }}.
                    <span v-html="questions[intque - 1].strQuestion3"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[0].thumbnail}`"
                      :id="`img_ex${episodess[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[0].strValue,
                          episodess[0].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque - 1].A"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[1].thumbnail}`"
                      :id="`img_ex${episodess[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[1].strValue,
                          episodess[1].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque - 1].B"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[2].thumbnail}`"
                      :id="`img_ex${episodess[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[2].strValue,
                          episodess[2].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque - 1].C"></span>
                  </td>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td>
                    <img
                      :src="`${episodess[3].thumbnail}`"
                      :id="`img_ex${episodess[3].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[3].strValue,
                          episodess[3].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque - 1].D"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    {{ questions[intque].intQNo }}.
                    <span v-html="questions[intque].strQuestion3"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess2[0].thumbnail}`"
                      :id="`img_ex2${episodess2[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs2(
                          intque + 1,
                          episodess2[0].strValue,
                          episodess2[0].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque].A"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess2[1].thumbnail}`"
                      :id="`img_ex2${episodess2[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs2(
                          intque + 1,
                          episodess2[1].strValue,
                          episodess2[1].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque].B"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess2[2].thumbnail}`"
                      :id="`img_ex2${episodess2[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs2(
                          intque + 1,
                          episodess2[2].strValue,
                          episodess2[2].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque].C"></span>
                  </td>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td>
                    <img
                      :src="`${episodess2[3].thumbnail}`"
                      :id="`img_ex2${episodess2[3].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs2(
                          intque + 1,
                          episodess2[3].strValue,
                          episodess2[3].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque].D"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 2)" class="btn btn-primary">
                Next
              </button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'r10'">
            <table class="time" style="float: right">
              <tr>
                <td width="230">
                  <table
                    width="0"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                      background: #efefef;
                      border-radius: 50px;
                      height: 40px;
                      line-height: 40px;
                      margin: 0 auto;
                    "
                  >
                    <tr>
                      <td width="213" align="center" class="style59">
                        <img
                          src="https://childu.co.kr/img/test_clock.png"
                          style="
                            width: 26px;
                            position: relative;
                            top: -2px;
                            margin-right: 8px;
                          "
                        />
                        <span id="spanClock">{{ resTimeData }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    <span v-html="questions[intque - 1].strQuestion"></span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="style37">
                    <span v-html="questions[intque - 1].strQuestion2"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    {{ questions[intque - 1].intQNo }}.
                    <span v-html="questions[intque - 1].strQuestion3"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[0].thumbnail}`"
                      :id="`img_ex${episodess[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[0].strValue,
                          episodess[0].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque - 1].A"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[1].thumbnail}`"
                      :id="`img_ex${episodess[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[1].strValue,
                          episodess[1].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque - 1].B"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[2].thumbnail}`"
                      :id="`img_ex${episodess[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[2].strValue,
                          episodess[2].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque - 1].C"></span>
                  </td>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td>
                    <img
                      :src="`${episodess[3].thumbnail}`"
                      :id="`img_ex${episodess[3].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[3].strValue,
                          episodess[3].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque - 1].D"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    {{ questions[intque].intQNo }}.
                    <span v-html="questions[intque].strQuestion3"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess2[0].thumbnail}`"
                      :id="`img_ex2${episodess2[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs2(
                          intque + 1,
                          episodess2[0].strValue,
                          episodess2[0].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque].A"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess2[1].thumbnail}`"
                      :id="`img_ex2${episodess2[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs2(
                          intque + 1,
                          episodess2[1].strValue,
                          episodess2[1].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque].B"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess2[2].thumbnail}`"
                      :id="`img_ex2${episodess2[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs2(
                          intque + 1,
                          episodess2[2].strValue,
                          episodess2[2].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque].C"></span>
                  </td>
                </tr>
                <tr v-if="questions[intque].intEcnt != '3'">
                  <td>
                    <img
                      :src="`${episodess2[3].thumbnail}`"
                      :id="`img_ex2${episodess2[3].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs2(
                          intque + 1,
                          episodess2[3].strValue,
                          episodess2[3].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque].D"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    {{ questions[intque + 1].intQNo }}.
                    <span v-html="questions[intque + 1].strQuestion3"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess3[0].thumbnail}`"
                      :id="`img_ex2${episodess3[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs3(
                          intque + 2,
                          episodess3[0].strValue,
                          episodess3[0].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque + 1].A"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess3[1].thumbnail}`"
                      :id="`img_ex2${episodess3[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs3(
                          intque + 2,
                          episodess3[1].strValue,
                          episodess3[1].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque + 1].B"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess3[2].thumbnail}`"
                      :id="`img_ex2${episodess3[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs3(
                          intque + 2,
                          episodess3[2].strValue,
                          episodess3[2].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque + 1].C"></span>
                  </td>
                </tr>
                <tr v-if="questions[intque + 1].intEcnt != '3'">
                  <td>
                    <img
                      :src="`${episodess3[3].thumbnail}`"
                      :id="`img_ex2${episodess3[3].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs3(
                          intque + 2,
                          episodess3[3].strValue,
                          episodess3[3].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38" width="600">
                    <span v-html="questions[intque + 1].D"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 3)" class="btn btn-primary">
                Next
              </button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'r11'">
            <table class="time" style="float: right">
              <tr>
                <td width="230">
                  <table
                    width="0"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                      background: #efefef;
                      border-radius: 50px;
                      height: 40px;
                      line-height: 40px;
                      margin: 0 auto;
                    "
                  >
                    <tr>
                      <td width="213" align="center" class="style59">
                        <span id="spanClock">{{ resTimeData }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    <span v-html="questions[intque - 1].strQuestion"></span>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="style37">
                    <span v-html="questions[intque - 1].strQuestion2"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    {{ questions[intque - 1].intQNo }}.
                    <span v-html="questions[intque - 1].strQuestion3"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[0].thumbnail}`"
                      :id="`img_ex${episodess[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[0].strValue,
                          episodess[0].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].A"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[1].thumbnail}`"
                      :id="`img_ex${episodess[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[1].strValue,
                          episodess[1].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].B"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess[2].thumbnail}`"
                      :id="`img_ex${episodess[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[2].strValue,
                          episodess[2].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].C"></span>
                  </td>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td>
                    <img
                      :src="`${episodess[3].thumbnail}`"
                      :id="`img_ex${episodess[3].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs(
                          intque,
                          episodess[3].strValue,
                          episodess[3].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque - 1].D"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    {{ questions[intque].intQNo }}.
                    <span v-html="questions[intque].strQuestion3"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess2[0].thumbnail}`"
                      :id="`img_ex2${episodess2[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs2(
                          intque + 1,
                          episodess2[0].strValue,
                          episodess2[0].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque].A"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess2[1].thumbnail}`"
                      :id="`img_ex2${episodess2[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs2(
                          intque + 1,
                          episodess2[1].strValue,
                          episodess2[1].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque].B"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess2[2].thumbnail}`"
                      :id="`img_ex2${episodess2[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs2(
                          intque + 1,
                          episodess2[2].strValue,
                          episodess2[2].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque].C"></span>
                  </td>
                </tr>
                <tr v-if="questions[intque].intEcnt != '3'">
                  <td>
                    <img
                      :src="`${episodess2[3].thumbnail}`"
                      :id="`img_ex2${episodess2[3].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs2(
                          intque + 1,
                          episodess2[3].strValue,
                          episodess2[3].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque].D"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    {{ questions[intque + 1].intQNo }}.
                    <span v-html="questions[intque + 1].strQuestion3"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess3[0].thumbnail}`"
                      :id="`img_ex2${episodess3[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs3(
                          intque + 2,
                          episodess3[0].strValue,
                          episodess3[0].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque + 1].A"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess3[1].thumbnail}`"
                      :id="`img_ex2${episodess3[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs3(
                          intque + 2,
                          episodess3[1].strValue,
                          episodess3[1].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque + 1].B"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess3[2].thumbnail}`"
                      :id="`img_ex2${episodess3[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs3(
                          intque + 2,
                          episodess3[2].strValue,
                          episodess3[2].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque + 1].C"></span>
                  </td>
                </tr>
                <tr v-if="questions[intque + 1].intEcnt != '3'">
                  <td>
                    <img
                      :src="`${episodess3[3].thumbnail}`"
                      :id="`img_ex2${episodess3[3].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs3(
                          intque + 2,
                          episodess3[3].strValue,
                          episodess3[3].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque + 1].D"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    {{ questions[intque + 2].intQNo }}.
                    <span v-html="questions[intque + 2].strQuestion3"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess4[0].thumbnail}`"
                      :id="`img_ex2${episodess4[0].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs4(
                          intque + 3,
                          episodess4[0].strValue,
                          episodess4[0].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque + 2].A"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess4[1].thumbnail}`"
                      :id="`img_ex2${episodess4[1].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs4(
                          intque + 3,
                          episodess4[1].strValue,
                          episodess4[1].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque + 2].B"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      :src="`${episodess4[2].thumbnail}`"
                      :id="`img_ex2${episodess4[2].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs4(
                          intque + 3,
                          episodess4[2].strValue,
                          episodess4[2].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque + 2].C"></span>
                  </td>
                </tr>
                <tr v-if="questions[intque + 1].intEcnt != '3'">
                  <td>
                    <img
                      :src="`${episodess4[3].thumbnail}`"
                      :id="`img_ex2${episodess4[3].num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelExs4(
                          intque + 3,
                          episodess4[3].strValue,
                          episodess4[3].num,
                          4
                        )
                      "
                    />
                  </td>
                  <td class="style38">
                    <span v-html="questions[intque + 2].D"></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 4)" class="btn btn-primary">
                Next
              </button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'r13'">
            <table class="time" style="float: right">
              <tr>
                <td width="230">
                  <table
                    width="0"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                      background: #efefef;
                      border-radius: 50px;
                      height: 40px;
                      line-height: 40px;
                      margin: 0 auto;
                    "
                  >
                    <tr>
                      <td width="213" align="center" class="style59">
                        <img
                          src="https://childu.co.kr/img/test_clock.png"
                          style="
                            width: 26px;
                            position: relative;
                            top: -2px;
                            margin-right: 8px;
                          "
                        />
                        <span id="spanClock">{{ resTimeData }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="style38">
                    {{ questions[intque - 1].intQNo }}.
                    <span v-html="questions[intque - 1].strQuestion"></span>
                  </td>
                </tr>
                <tr>
                  <td colspan="3" style="padding-top: 30px" class="style37">
                    <span v-html="questions[intque - 1].strQuestion2"></span>
                  </td>
                </tr>
                <template
                  v-if="
                    $route.params.level === '5' &&
                    questions[intque - 1].intOrder === '7'
                  "
                >
                  <tr>
                    <td>
                      <img
                        :src="`${episodess[0].thumbnail}`"
                        :id="`img_ex${episodess[0].num}`"
                        style="cursor: pointer"
                        v-on:click="
                          SelExs(
                            intque,
                            episodess[0].strValue,
                            episodess[0].num,
                            4
                          )
                        "
                      />
                    </td>
                    <td>
                      <img
                        v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                          questions[intque - 1].A_file
                        }.jpg`"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        :src="`${episodess[1].thumbnail}`"
                        :id="`img_ex${episodess[1].num}`"
                        style="cursor: pointer"
                        v-on:click="
                          SelExs(
                            intque,
                            episodess[1].strValue,
                            episodess[1].num,
                            4
                          )
                        "
                      />
                    </td>
                    <td>
                      <img
                        v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                          questions[intque - 1].B_file
                        }.jpg`"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        :src="`${episodess[2].thumbnail}`"
                        :id="`img_ex${episodess[2].num}`"
                        style="cursor: pointer"
                        v-on:click="
                          SelExs(
                            intque,
                            episodess[2].strValue,
                            episodess[2].num,
                            4
                          )
                        "
                      />
                    </td>
                    <td>
                      <img
                        v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                          questions[intque - 1].C_file
                        }.jpg`"
                      />
                    </td>
                  </tr>
                  <tr v-if="questions[intque - 1].intEcnt != '3'">
                    <td>
                      <img
                        :src="`${episodess[3].thumbnail}`"
                        :id="`img_ex${episodess[3].num}`"
                        style="cursor: pointer"
                        v-on:click="
                          SelExs(
                            intque,
                            episodess[3].strValue,
                            episodess[3].num,
                            4
                          )
                        "
                      />
                    </td>
                    <td>
                      <img
                        v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                          questions[intque - 1].D_file
                        }.jpg`"
                      />
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td v-for="(episode, i) in episodes" :key="i">
                      <img
                        :src="episode.thumbnail"
                        :id="`img_ex${episode.num}`"
                        style="cursor: pointer; margin-top: 30px"
                        v-on:click="
                          SelEx(intque, episode.strValue, episode.num, 3)
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                          questions[intque - 1].A_file
                        }.jpg`"
                        style="padding-top: 10px"
                      />
                    </td>
                    <td>
                      <img
                        v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                          questions[intque - 1].B_file
                        }.jpg`"
                      />
                    </td>
                    <td>
                      <img
                        v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                          questions[intque - 1].C_file
                        }.jpg`"
                      />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">
                Next
              </button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'r14'">
            <table class="time" style="float: right">
              <tr>
                <td width="230">
                  <table
                    width="0"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="
                      background: #efefef;
                      border-radius: 50px;
                      height: 40px;
                      line-height: 40px;
                      margin: 0 auto;
                    "
                  >
                    <tr>
                      <td width="213" align="center" class="style59">
                        <img
                          src="https://childu.co.kr/img/test_clock.png"
                          style="
                            width: 26px;
                            position: relative;
                            top: -2px;
                            margin-right: 8px;
                          "
                        />
                        <span id="spanClock">{{ resTimeData }}</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="4" class="style38" style="text-align: center">
                    {{ questions[intque - 1].intQNo }}.
                    <span v-html="questions[intque - 1].strQuestion"></span>
                  </td>
                </tr>
                <tr>
                  <td colspan="3" height="50">
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].Q_file
                      }.jpg`"
                      style="margin: 0 0 30px"
                    />
                  </td>
                </tr>
                <tr>
                  <td v-for="(episode, i) in episodes" :key="i">
                    <img
                      :src="episode.thumbnail"
                      :id="`img_ex${episode.num}`"
                      style="cursor: pointer"
                      v-on:click="
                        SelEx(intque, episode.strValue, episode.num, 3)
                      "
                    />
                  </td>
                </tr>
                <tr>
                  <td width="300" style="padding-top: 30px">
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].A_file
                      }.jpg`"
                    />
                  </td>
                  <td width="300" style="padding-top: 30px">
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].B_file
                      }.jpg`"
                    />
                  </td>
                  <td width="300" style="padding-top: 30px">
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].C_file
                      }.jpg`"
                    />
                  </td>
                  <td v-if="questions[intque - 1].intEcnt != '3'">
                    <img
                      v-bind:src="`https://www.useschool.co.kr/PT/LT/img/${
                        questions[intque - 1].D_file
                      }.jpg`"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">
                Next
              </button>
            </div>
          </template>
        </template>
      </tbody>
    </table>
  </div>
  <div style="position: absolute; bottom: 0"></div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    questions: [],
    answer: [],
    result: [],
    audio: [],
    intque: null,
    timeCounter: 2400, //3분
    resTimeData: "",
    strState: "Start",
    episodes: [
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num1.png",
        strValue: "A",
        num: "1",
      },
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num2.png",
        strValue: "B",
        num: "2",
      },
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num3.png",
        strValue: "C",
        num: "3",
      },
    ],
    episodess: [
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num1.png",
        strValue: "A",
        num: "1",
      },
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num2.png",
        strValue: "B",
        num: "2",
      },
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num3.png",
        strValue: "C",
        num: "3",
      },
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num4.png",
        strValue: "D",
        num: "4",
      },
    ],
    episodess2: [
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num1.png",
        strValue: "A",
        num: "1",
      },
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num2.png",
        strValue: "B",
        num: "2",
      },
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num3.png",
        strValue: "C",
        num: "3",
      },
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num4.png",
        strValue: "D",
        num: "4",
      },
    ],
    episodess3: [
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num1.png",
        strValue: "A",
        num: "1",
      },
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num2.png",
        strValue: "B",
        num: "2",
      },
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num3.png",
        strValue: "C",
        num: "3",
      },
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num4.png",
        strValue: "D",
        num: "4",
      },
    ],
    episodess4: [
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num1.png",
        strValue: "A",
        num: "1",
      },
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num2.png",
        strValue: "B",
        num: "2",
      },
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num3.png",
        strValue: "C",
        num: "3",
      },
      {
        thumbnail: "https://useschool.co.kr/PT/LT/images/Num4.png",
        strValue: "D",
        num: "4",
      },
    ],
  }),
  mounted() {
    axios
      .get(this.$root.baseUrl + "/leveltest/" + this.$route.params.level)
      .then((response) => {
        if (response.data.error) {
          alert("레벨테스트를 불러올 수 없습니다.\n" + response.data.error);
        } else {
          this.questions = response.data.result;
          this.intque = 0;
          //this.Timerstart();
        }
      });
  },
  methods: {
    playAudio(url) {
      new Audio(url).play();
    },
    nextTest(intNum, snum) {
      //console.log(this.audio[intNum - 1]);
      if (this.audio[intNum - 1]) {
        this.audio[intNum - 1].pause();
      }
      this.strState = "";
      for (var i = 0; i < snum; i++) {
        //console.log(intNum + i);
        if (!this.answer[intNum + i]) {
          console.log(intNum + i);
          $.notify(
            {
              // options
              message: "문제에 답을 선택하세요. ",
            },
            {
              // settings
              type: "danger",
              z_index: 9999,
            }
          );
          return;
        }
      }

      if (this.intque == 10) {
        if (
          this.$route.params.level == "Phonics" ||
          this.$route.params.level == "kinder" ||
          this.$route.params.level == "1" ||
          this.$route.params.level == "2"
        ) {
          this.strState = "LEnd";
        } else {
          this.intque = intNum + snum;
        }
      } else if (this.intque == 25) {
        if (
          this.$route.params.level == "Phonics" ||
          this.$route.params.level == "kinder" ||
          this.$route.params.level == "1" ||
          this.$route.params.level == "2"
        ) {
          this.strState = "REnd";
        } else {
          this.intque = intNum + snum;
        }
      } else if (this.intque + 1 == 33) {
        this.strState = "REnd";
      } else if (this.intque + 2 == 33 && this.$route.params.level != "7") {
        this.strState = "REnd";
      } else {
        this.intque = intNum + snum;
        if (this.questions[intNum].Q_mpfile) {
          this.audio[intNum] = new Audio(
            "https://www.useschool.co.kr/PT/LT/data/" +
              this.questions[intNum].Q_mpfile +
              ".mp3"
          );
          this.audio[intNum].play();
          //console.log(this.audio[intNum]);
        }
        console.log(intNum);
        console.log(this.questions[intNum].strQType);
      }

      for (var i = 1; i <= 3; i++) {
        this.episodes[i - 1].thumbnail =
          "https://useschool.co.kr/PT/LT/images/Num" + i + ".png";
        this.episodess[i - 1].thumbnail =
          "https://useschool.co.kr/PT/LT/images/Num" + i + ".png";
        this.episodess2[i - 1].thumbnail =
          "https://useschool.co.kr/PT/LT/images/Num" + i + ".png";
        this.episodess3[i - 1].thumbnail =
          "https://useschool.co.kr/PT/LT/images/Num" + i + ".png";
        this.episodess4[i - 1].thumbnail =
          "https://useschool.co.kr/PT/LT/images/Num" + i + ".png";
      }
      this.episodess[3].thumbnail =
        "https://useschool.co.kr/PT/LT/images/Num4.png";
      this.episodess2[3].thumbnail =
        "https://useschool.co.kr/PT/LT/images/Num4.png";
      this.episodess3[3].thumbnail =
        "https://useschool.co.kr/PT/LT/images/Num4.png";
      this.episodess4[3].thumbnail =
        "https://useschool.co.kr/PT/LT/images/Num4.png";
    },
    nextLStart(intNum) {
      this.intque++;
      this.audio[intNum] = new Audio(
        "https://www.useschool.co.kr/PT/LT/data/" +
          this.questions[intNum].Q_mpfile +
          ".mp3"
      );
      this.audio[intNum].play();
      clearInterval(this.polling);
      this.timeCounter = 1200;
      this.startTime();
      console.log(this.questions[intNum].strQType);
      //this.playAudio("https://www.useschool.co.kr/PT/LT/data/" + this.questions[intNum].Q_mpfile + ".mp3");
    },
    nextLEnd() {
      this.strState = "RStart";
      clearInterval(this.polling);
      this.timeCounter = 1200;
    },
    nextRStart(intNum) {
      this.strState = "";
      this.intque++;
      this.startTime();
      console.log(this.questions[intNum].strQType);
      //console.log(this.$root.user);
    },
    goLStep() {
      var today = new Date();
      var year = today.getFullYear();
      var month = ("0" + (today.getMonth() + 1)).slice(-2);
      var day = ("0" + today.getDate()).slice(-2);
      var hours = ("0" + today.getHours()).slice(-2);
      var minutes = ("0" + today.getMinutes()).slice(-2);
      var seconds = ("0" + today.getSeconds()).slice(-2);

      var dateString =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;

      this.answer[0] = dateString;

      if (
        this.$route.params.level == "Phonics" ||
        this.$route.params.level == "kinder" ||
        this.$route.params.level == "1" ||
        this.$route.params.level == "2"
      ) {
        this.strState = "LStart";
      } else {
        this.strState = "";
        this.intque++;
        this.timeCounter = 2400;
        this.startTime();
        console.log(this.questions[0].strQType);
      }
    },
    nextEnd() {
      //console.log(this.$root.user);

      clearInterval(this.polling);

      let form = new FormData();
      form.append("answer", this.answer);
      form.append("level", this.$route.params.level);
      if (!this.$route.params.idx) {
        form.append("user_idx", this.$root.user.id);
        form.append("user_createdAt", this.$root.user.createdAt);
        form.append("user_business", this.$root.user.business);
        form.append("user_id", this.$root.user.username);
        form.append("user_name", this.$root.user.name);
      } else {
        form.append("testIdx", this.$route.params.idx);
      }
      axios
        .post(this.$root.baseUrl + "/leveltest/Result", form)
        .then((response) => {
          return new Promise((resolve) => {
            if (response.data.error) {
              alert("처리 중 오류가 발생했습니다.\n" + response.data.error);
            } else {
              //console.log(response.data);
              this.strState = "sEnd";
              this.result = response.data.result;
            }
          });
        });
    },
    SelEx(intNum, strValue, strNum, MaxNum) {
      this.answer[intNum] = strValue;

      //console.log(this.answer[intNum]);
      for (var i = 1; i <= MaxNum; i++) {
        if (strNum == i) {
          this.episodes[i - 1].thumbnail =
            "https://useschool.co.kr/PT/LT/images/Num2" + i + ".png";
        } else {
          this.episodes[i - 1].thumbnail =
            "https://useschool.co.kr/PT/LT/images/Num" + i + ".png";
        }
      }
    },
    SelExs(intNum, strValue, strNum, MaxNum) {
      this.answer[intNum] = strValue;

      for (var i = 1; i <= MaxNum; i++) {
        if (strNum == i) {
          this.episodess[i - 1].thumbnail =
            "https://useschool.co.kr/PT/LT/images/Num2" + i + ".png";
        } else {
          this.episodess[i - 1].thumbnail =
            "https://useschool.co.kr/PT/LT/images/Num" + i + ".png";
        }
      }
    },
    SelExs2(intNum, strValue, strNum, MaxNum) {
      this.answer[intNum] = strValue;

      for (var i = 1; i <= MaxNum; i++) {
        if (strNum == i) {
          this.episodess2[i - 1].thumbnail =
            "https://useschool.co.kr/PT/LT/images/Num2" + i + ".png";
        } else {
          this.episodess2[i - 1].thumbnail =
            "https://useschool.co.kr/PT/LT/images/Num" + i + ".png";
        }
      }
    },
    SelExs3(intNum, strValue, strNum, MaxNum) {
      this.answer[intNum] = strValue;

      for (var i = 1; i <= MaxNum; i++) {
        if (strNum == i) {
          this.episodess3[i - 1].thumbnail =
            "https://useschool.co.kr/PT/LT/images/Num2" + i + ".png";
        } else {
          this.episodess3[i - 1].thumbnail =
            "https://useschool.co.kr/PT/LT/images/Num" + i + ".png";
        }
      }
    },
    SelExs4(intNum, strValue, strNum, MaxNum) {
      this.answer[intNum] = strValue;

      for (var i = 1; i <= MaxNum; i++) {
        if (strNum == i) {
          this.episodess4[i - 1].thumbnail =
            "https://useschool.co.kr/PT/LT/images/Num2" + i + ".png";
        } else {
          this.episodess4[i - 1].thumbnail =
            "https://useschool.co.kr/PT/LT/images/Num" + i + ".png";
        }
      }
    },
    startTime() {
      // 1초에 한번씩 start 호출
      this.polling = setInterval(() => {
        this.timeCounter--; //1찍 감소
        this.resTimeData = this.prettyTime();
        //console.log(this.resTimeData);
        if (this.timeCounter <= 0) this.timeStop();
      }, 1000);
    },
    // 시간 형식으로 변환 리턴
    prettyTime() {
      let time = this.timeCounter / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return this.pad(minutes, 2) + ":" + this.pad(secondes, 2);
    },
    // 2자리수로 만들어줌 09,08...
    pad(n, width) {
      n = n + "";
      return n.length >= width
        ? n
        : new Array(width - n.length + 1).join("0") + n;
    },
    timeStop() {
      clearInterval(this.polling);
    },
  },
};
</script>
