<style>
.table{padding:10px;}
.table td{border: 1px solid #f0f0f0; padding:10px;}
.table th{background: #f8f8f8; height: 50px; text-align: center; border:1px solid #f0f0f0;}
</style>
<template>
<div class="container" style="padding:20px 0;">

    <br>
    <table class="table">
      <colgroup>
        <col style="width:25%;" />
        <col style="width:25%;" />
        <col style="width:25%;" />
        <col style="width:25%;" />
      </colgroup>
        <tr>
            <th>제목</th>
            <td colspan="2">{{ article.title }}</td>
            <td>{{ article.created_at }}</td>
        </tr>
        <tr>
          <td colspan="4">
            <div v-html="article.content"></div>
          </td>
        </tr>
    </table>

    <button v-on:click="this.$router.go(-1);" class="btn btn-primary" style="padding:10px 40px; font-size:16px;">목록</button>
    </div>
</template>

<script>
import axios from 'axios';
export default {
  data: () => ({
    article: {

    }
  }),
  methods: {
    loadBoard() {
              axios.get(this.$root.baseUrl + '/articles/' + this.$route.params.article
                ).then((response) => {
                  if(response.data.error){
                    alert("게시글을 불러올 수 없습니다.\n" + response.data.error)

                  } else {
                    this.article = response.data.result;
                  }


                })
    }
  },
  mounted(){
     this.boards = {
         "공지사항": "notice,notice_" + this.$root.campus.subdomain,
         "포토갤러리": "photo_" + this.$root.campus.subdomain
     }
     this.selBoard = "공지사항";
    this.loadBoard()



  }
}
</script>
