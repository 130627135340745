


<template>
<div style="background: url(https://bizstudy.useschool.co.kr/img/cpt/cpt_head_title_bg.png) center repeat; width: 100%; margin: 0 auto; height: 125px; border-bottom: 1px solid #ccc;">
  <div style="display: block; width: 1000px; margin: 0 auto;">
    <img src="https://bizstudy.useschool.co.kr/img/cpt/cpt_head_title.png" />
  </div>
</div>




  <div class="container_leveltest" style="backgroundl;">
        <table class="table_question">
      <tbody>
        <template v-if="strState === 'Start'">

          <!--테이블로 만든
          <div class="content">
            <table class="table table-striped" width="80%" border="0">
               <tbody>
                <tr>
                  <td colspan="2" class="first_td">Phonics의 테스트는?</td>
               </tr>
                <tr>
                  <td>Listening Test(듣기 영역)</td>
                  <td class="red">10문항</td>
               </tr>
                <tr>
                  <td>Reading Test(읽기 영역)</td>
                  <td class="red">15문항</td>
               </tr>
               <tr>
                  <td class="last">Total</td>
                  <td class="last red">25문항</td>
               </tr>
               </tbody>
            </table>

            <table class="table table-striped" width="80%" border="0">
               <tbody>
                <tr>
                  <td class="first_td">시험 중 유의사항</td>
               </tr>
                <tr>
                  <td>문제를 풀 수 있는 시간은 총 40분입니다.</td>
               </tr>
                <tr>
                  <td>시험 도중 화면을 끄면 시험이 저장되지 않습니다.</td>
               </tr>
               <tr>
                  <td class="last">모든 문제를 풀 때까지 시험을 종료하지 않도록 주의하십시오.</td>
               </tr>
              </tbody>
            </table>
        </div>-->


          <table width="100%" border="0">
            <tbody>
              <tr>
                <td v-if="$route.params.level">
                  <ul class="info" style="padding:0!important;">
                    <li style="width:290px;"><img src="https://bizstudy.useschool.co.kr/img/cpt/img_listening.png"></li>
                    <li style="width:290px;"><img src="https://bizstudy.useschool.co.kr/img/cpt/img_reading.png"></li>
                    <li style="width:290px;"><img src="https://bizstudy.useschool.co.kr/img/cpt/img_speak.png"></li>
                    <li style="width:290px;"><img src="https://bizstudy.useschool.co.kr/img/cpt/img_writing.png"></li>
                  <div style="clear: both; margin-bottom: 14px;"></div>
                  <div style="margin:0 auto; margin-top: 80px;">
                    <span class="info_text" style="background: #70d0f8; font-weight:700; color: #fff; padding: 10px 30px; border-radius: 30px;">총 30문항 100점 만점으로 구성되었습니다.</span>
                  </div>
                    <div style="display:inline-block; margin: 50px auto;">
                      <ul class="text_careful">
                        <li class="test_noti" style="list-style: none;">시험중 유의사항</li>
                        <li>시험 중 다음 문제로 넘어가면 이전 문제로 되돌아 갈 수 없습니다.</li>
                        <li>시험 도중 화면을 끄면 시험이 저장되지 않습니다.</li>
                        <li>모든 문제를 풀 때까지 시험을 종료하지 않도록 주의하십시오.</li>
                      </ul>
                    </div>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="text-align:center;">
          <button v-on:click="goLStep()" class="btn btn-primary">START</button>
          </div>
        </template>




        <template v-else-if="strState === 'LStart' && intque === 0">

          <!--테이블로 만든
               <table class="table" width="90%" border="0">
                <tbody>
                  <tr>
                    <td class="first_td"> Listening 듣기영역</td>
                  </tr>
                  <tr>
                    <td>지금부터 Listening Test를 시작하겠습니다. <br/></td>
                  </tr>
                  <tr>
                    <td>화면 상단의 제한시간(20분)을 유의해 주시기 바랍니다.</td>
                    </tr>
                </tbody>
              </table>

            <table class="table" width="90%" border="0">
                <tbody>
                  <tr>
                    <td class="first_td"> 시험 중 유의사항</td>
                  </tr>
                  <tr>
                    <td>듣기 테스트를 위하여 '헤드셋' 또는 '스피커'를 확인하기 바랍니다.</td>
                  </tr>
                </tbody>
              </table> -->
        <!--리스닝 테스트 시작-->
              <div class="row">
                <div  style="width:600px; margin: 0 auto;" >
                  <div class="main-card mb-3 card" style="border: none;">
                    <div class="card-body" style="border: none;text-align: center;">
                      <ul class="info" style="padding:0!important;">
                        <img src="https://bizstudy.useschool.co.kr/img/cpt/img_listening.png" style="margin-bottom: 50px;"/>

                        <div style="clear: both; margin-bottom: 14px;">
                          <span class="info_text">
                          지금부터 Listening Test를 시작하겠습니다. <br/>
                          시험은 총 <span class="point_text">10문제</span>이고 제한시간은 <span class="point_text">10분</span>입니다.</span>
                        </div>

                        <ul class="text_careful" style="margin: 60px 0;">
                          <li class="test_noti" style="list-style: none;">시험중 유의사항</li>
                          <li>듣기 테스트를 위하여 '헤드셋' 또는 '스피커'를 확인하기 바랍니다.</li>
                        </ul>
                      </ul>
                      <div>
                      <button v-on:click="nextLStart(intque)" class="btn btn-primary">NEXT</button></div>
                      </div>
                    </div>
                      </div>
                  </div>
        </template>

        <!--리스닝 테스트 끝-->
        <template v-else-if="strState === 'LEnd'">
          <div class="row">
                <div  style="width:600px; margin:0 auto;" >
                  <div class="main-card mb-3 card" style="border: none;">
                    <div class="card-body" style="border: none; text-align: center;">
                      <ul class="info" style="padding:0!important;">
                        <img src="https://bizstudy.useschool.co.kr/img/cpt/img_listening.png" style="margin-bottom: 50px;">
                        <div style="clear: both; margin-bottom: 14px;"></div>
                        <span class="info_text" style=" padding-top: 15px; background: #70d0f8; color: #fff; padding: 4px 20px; border-radius: 20px; text-align: center;">Listening Test가 끝났습니다.</span>
                          <p style="margin: 50px 0 50px 0; text-align: center;">다음은 Reading Test입니다. 준비가 되면 “Next” 버튼을 누르십시오.</p>
                      </ul>
                      <div>


                      <button v-on:click="nextLEnd()" class="btn btn-primary">NEXT</button></div>
                    </div>
                    </div>
                </div>
          </div>
        </template>

        <!--리딩 테스트 시작-->
        <template v-else-if="strState === 'RStart'">
          <div class="row">
                <div  style="width:600px; margin:0 auto;" >
                  <div class="main-card mb-3 card" style="border: none;">
                    <div class="card-body" style="border: none;text-align: center;">
                    <ul class="info" style="padding:0!important;">
                      <img src="https://bizstudy.useschool.co.kr/img/cpt/img_reading.png" style="margin-bottom: 50px;">
                      <div style="clear: both;margin-bottom: 14px;">
                      <span class="info_text">
                        지금부터 Reading Test를 시작하겠습니다. <br/>
                        시험은 총 <span class="point_text">10문제</span>이고 제한시간은<span class="point_text"> 20분</span>입니다.
                      </span>
                    </div>
                      <ul class="text_careful" style="margin: 60px 0;">
                        <li class="test_noti" style="list-style: none;">시험중 유의사항</li>
                        <li>문제를 잘 읽고 질문에 대답하세요.</li>
                      </ul>
                    </ul>

                    <div>
                    <button v-on:click="nextRStart(intque)" class="btn btn-primary">NEXT</button></div>
                    </div>
                    </div>
                      </div>
          </div>
        </template>
        <!--리딩 테스트 끝-->
        <template v-else-if="strState === 'REnd'">
          <div class="row">
                <div  style="width:600px; margin:0 auto;" >
                  <div class="main-card mb-3 card" style="border: none;">
                    <div class="card-body" style="border: none;text-align: center;">
                      <ul class="info" style="padding:0!important;">
                      <img src="https://bizstudy.useschool.co.kr/img/cpt/img_reading.png" style="margin-bottom: 50px;">
                        <div style="clear: both;margin-bottom: 14px;"></div>
                        <span class="info_text" style="padding-top: 15px; background: #70d0f8; color: #fff; padding: 4px 20px; border-radius: 20px; text-align: center;">Reading Test가 끝났습니다.</span>
                        <p style="margin: 50px 0 50px 0; text-align: center;">다음은 Speaking Test입니다. 준비가 되면 “Next” 버튼을 누르십시오.</p>
                      </ul>
                      <div>
                      <button v-on:click="nextREnd()" class="btn btn-primary">NEXT</button></div>
                  </div>
                </div>
          </div>
          </div>
        </template>
        <!--스피킹 테스트 시작-->
        <template v-else-if="strState === 'SStart'">
          <div class="row">
                <div  style="width:600px; margin:0 auto;" >
                  <div class="main-card mb-3 card" style="border: none;">
                    <div class="card-body" style="border: none; text-align: center;">
                      <ul class="info" style="padding:0!important;">
                      <img src="https://bizstudy.useschool.co.kr/img/cpt/img_speak.png" style="margin-bottom: 50px;">
                      <div style="clear: both;margin-bottom: 14px;"></div>
                      <span class="info_text">
                        지금부터 Speaking Test를 시작하겠습니다. <br/>
                        시험은 총 <span class="point_text">5문제</span>이고 제한시간은<span class="point_text"> 10분</span>입니다.
                      </span>
                      <ul class="text_careful" style="margin: 60px 0;">
                        <li class="test_noti" style="list-style: none;">시험중 유의사항</li>
                        <li>본 시험은 별도의 마이크가 필요 없는 객관식 시험입니다.</li>
                      </ul>
                    </ul>
                    <div>
                    <button v-on:click="nextSStart(intque)" class="btn btn-primary">NEXT</button></div>
                    </div>
                  </div>
                </div>
          </div>
        </template>
        <!--스피킹 테스트 끝-->
        <template v-else-if="strState === 'SEnd'">
          <div class="row">
                <div style="width:600px; margin:0 auto;" >
                  <div class="main-card mb-3 card" style="border: none;">
                    <div class="card-body" style="border: none;text-align: center;">
                      <ul class="info" style="padding:0!important;">
                      <img src="https://bizstudy.useschool.co.kr/img/cpt/img_speak.png" style="margin-bottom: 50px;">
                        <div style="clear: both;margin-bottom: 14px;"></div>
                        <span class="info_text" style="padding-top: 15px; background: #70d0f8; color: #fff; padding: 4px 20px; border-radius: 20px; text-align: center;">Speaking Test가 끝났습니다.</span>
                        <p style="margin: 50px 0 50px 0; text-align: center;">다음은 Writing Test입니다. 준비가 되면 “Next” 버튼을 누르십시오.</p>
                      </ul>
                      <div>


                    <button v-on:click="nextSEnd()" class="btn btn-primary">NEXT</button></div>
                    </div>
                  </div>
                </div>
          </div>
        </template>
        <!--라이팅 테스트 시작-->
        <template v-else-if="strState === 'WStart'">
          <div class="row">
                <div >
                  <div class="main-card mb-3 card" style="border: none;">
                    <div class="card-body" style="border: none;text-align: center;">
                      <ul class="info" style="margin-top: 60px;">
                      <img src="https://bizstudy.useschool.co.kr/img/cpt/writing.png">
                      <div style="clear: both;margin-bottom: 14px;"></div>
                      <span class="info_text" >
                        지금부터 Writing Test를 시작하겠습니다. <br/>
                        시험은 총 <span class="point_text">5문제</span>이고 제한시간은 <span class="point_text">10분</span>입니다.
                      </span>
                      <ul class="text_careful" style="margin: 60px 0;">
                        <li style="list-style: none; font-size: 20px; color:#70d0f8; width: 1200px;">시험중 유의사항</li>
                        <li>본시험은 별도의 마이크가 필요없는 객관식 시험입니다.</li>
                      </ul>
                    </ul>
                    <div>


                    <button v-on:click="nextWStart(intque)" class="btn btn-primary">NEXT</button></div>
                    </div>
                  </div>
                </div>
            </div>
        </template>

        <!--테스트 끝-->
        <template v-else-if="strState === 'ALLEnd'">
          <table width="100%">
            <tbody>
              <tr>
                <td><img src="https://www.childu.co.kr/PT/NT/images/img_finish.png" style="margin-bottom: 50px;"></td>
              </tr>
              <tr>
                <td><span style="font-size: 30px; font-weight: 700;">수고하셨습니다!</span><br/>모든 시험이 종료되었습니다.</td>
              </tr>
              <tr>
                <td>“Submit” 버튼을 눌러 제출해 주시기 바랍니다.</td>
              </tr>
            </tbody>
          </table>
          <div style="text-align:center;">
          <button v-on:click="nextEnd()" class="btn btn-primary" style="margin-top: 50px;">Submit</button>
          </div>
        </template>

        <!--시험 결과-->
        <template v-else-if="strState === 'sEnd'">

            <div style="margin: 0 auto; background: #fff;">
              <div style="text-align: center; margin: 0 auto;">
                <img src="http://bizstudy.useschool.co.kr/img/mypath_logo.png" style="width: 400px;">
              </div>
              <p style="font-size: 50px; text-align: center; font-family: 'Roboto Serif', sans-serif; margin: 10px 0; ">Placement Test</p>
              <p style="text-align: center;"><span style="font-weight: bold;">
                  {{$root.user.name}}</span>님의 시험결과는 다음과 같습니다.</p>
              <div style="width: 600px; margin: 0 auto; position: relative;">
                <div style="position: absolute; top:-15px; left: 15px;">
                    <img src="https://useschool.co.kr/img/star.png">
                </div>
              <div style="width: 600px; height: 90px; background: #eeeeee; text-align: center; margin: 20px auto; display: flex;">
                <div style="width: 280px; height: 90px; padding-top: 20px;">
                  <span style="font-size: 34px; vertical-align: middle;">
                    <span v-if="$route.params.level === '1' || $route.params.level === '2' || $route.params.level === '3'">STEP</span>{{$route.params.level}}</span>
                  </div>
                  <div style="width: 40px; margin-top: 30px;"><img src="https://useschool.co.kr/img/arrow.png"></div>
                  <div style="width: 280px; height: 90px; padding-top: 20px;">
                    <span style="font-size: 34px; color: #253b79; font-weight: bold;">{{result.t_total}}</span>
                    <span style="font-size: 34px;">점</span>
                  </div>
              </div>
              </div>

            </div>

        </template>


        <!--시험 시작-->
        <template v-else>
          <template v-if="intque && questions[intque - 1].strQType === 'L1'">
            <table class="time">
              <tr>
                <td width="650">&nbsp;</td>
                <!--시계 영역-->
                <td width="150">
                  <table width="150" border="0" cellspacing="0" cellpadding="0" style="background:#efefef; border-radius:50px; height:40px; line-height:40px; margin:0 auto;">
                    <tr>
                                            <td width="150" align="center" class="style59"><img src="https://childu.co.kr/img/test_clock.png" style="width:26px; position:relative; top:-4px;" /><span id="spanClock" style="font-size:20px; color:#333;margin-left:10px;">{{resTimeData}}</span></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <!-- 1~5번 답변 영역 -->
            <table class="table_question">
              <tbody>
                <tr>
                  <template v-if="questions[intque - 1].intEcnt != '3'">
                    <td colspan="4"  >{{ questions[intque - 1].intQNo }}. <span class="question"
                          v-html="questions[intque - 1].strQuestion"></span>
                    </td>
                  </template>
                  <template v-else>
                    <td colspan="3" class="question">{{ questions[intque - 1].intQNo }}. <span class="question"
                        v-html="questions[intque - 1].strQuestion"></span></td>
                  </template>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td class="answer" style="text-align:center;" v-for="(episode, i) in episodess" :key="i" ><img :src="episode.thumbnail"
                      v-on:click="SelExs(intque, episode.strValue, episode.num, 4)" /></td>
                </tr>
                <!--숫자 버튼 영역-->
                <tr v-else  style="text-align:center;">
                  <td class="answer" v-for="(episode, i) in episodes" :key="i"><img :src="episode.thumbnail"
                      v-on:click="SelEx(intque, episode.strValue, episode.num, 3)" /></td>
                </tr>
                <tr>
                  <td style="border:1px solid #ccc; width:240px;">
                    <img v-bind:src="`https://www.childu.co.kr/PT/NT/img/${questions[intque - 1].A_file}.jpg`" >
                  </td>

                  <td style="border:1px solid #ccc; width:240px;"><img v-bind:src="`https://www.childu.co.kr/PT/NT/img/${questions[intque - 1].B_file}.jpg`" >
                  </td>
                  <td style="border:1px solid #ccc;width:240px;"><img v-bind:src="`https://www.childu.co.kr/PT/NT/img/${questions[intque - 1].C_file}.jpg`" >
                  </td>
                  <td style="border:1px solid #ccc;width:240px;" v-if="questions[intque - 1].intEcnt != '3'">
                    <img v-bind:src="`https://www.childu.co.kr/PT/NT/img/${questions[intque - 1].D_file}.jpg`">
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button class="btn btn-danger"
                v-on:click="playAudio(`https://www.childu.co.kr/PT/NT/data/${questions[intque - 1].Q_mpfile}.mp3`)">Again</button>
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">Next</button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'L2'">
            <table class="time">
              <tr>
                <td width="650">&nbsp;</td>
                <td width="150">
                  <table width="150" border="0" cellspacing="0" cellpadding="0" style="background:#efefef; border-radius:50px; height:40px; line-height:40px; margin:0 auto;">
                    <tr>

                      <td width="150" align="center" class="style59"><img src="https://childu.co.kr/img/test_clock.png" style="width:26px; position:relative; top:-4px;" /><span id="spanClock" style="font-size:20px; color:#333;margin-left:10px;">{{resTimeData}}</span></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <!--step2 1번 파트 4번 문제 영역-->
            <table class="table_question">
              <tbody>
              <tr>
                <td>
                  <td colspan="4" class="question">{{ questions[intque - 1].intQNo }}. <span
                      v-html="questions[intque - 1].strQuestion" class="question"></span></td>
                </td>
              </tr>
                <div class="v_ans" style="margin-top: 50px;">
                <tr>
                  <td><img :src="`${episodess[0].thumbnail}`" :id="`img_ex${episodess[0].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[0].strValue, episodess[0].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].A"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[1].thumbnail}`" :id="`img_ex${episodess[1].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[1].strValue, episodess[1].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].B"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[2].thumbnail}`" :id="`img_ex${episodess[2].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[2].strValue, episodess[2].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].C"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[3].thumbnail}`" :id="`img_ex${episodess[3].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[3].strValue, episodess[3].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].D"></span></td>
                </tr>
              </div>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button class="btn btn-danger"
                v-on:click="playAudio(`https://www.childu.co.kr/PT/NT/data/${questions[intque - 1].Q_mpfile}.mp3`)">Again</button>
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">Next</button>
            </div>
          </template>
          <template v-if="intque && questions[intque - 1].strQType === 'L4'">
            <table class="time">
              <tr>
                <td width="650">&nbsp;</td>
                 <td width="150">
                  <table width="150" border="0" cellspacing="0" cellpadding="0" style="background:#efefef; border-radius:50px; height:40px; line-height:40px; margin:0 auto;">
                    <tr>

                      <td width="150" align="center" class="style59"><img src="https://childu.co.kr/img/test_clock.png" style="width:26px; position:relative; top:-4px;" /><span id="spanClock" style="font-size:20px; color:#333;margin-left:10px;">{{resTimeData}}</span></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <!--step2 1번 파트 1~3번 문제 영역-->
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="8" >{{ questions[intque - 1].intQNo }}. <span
                      v-html="questions[intque - 1].strQuestion" class="question"></span></td>
                </tr>
                <tr>
                  <td colspan="8">
                  <img v-bind:src="`https://www.childu.co.kr/PT/NT/img/${questions[intque - 1].Q_file}.jpg`" style="margin: 30px 0;"></td>
                </tr>
                <template v-if="questions[intque - 1].vhType === 'h'">
                <div class="v_ans">
                  <tr>
                    <td><img :src="`${episodess[0].thumbnail}`" :id="`img_ex${episodess[0].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[0].strValue, episodess[0].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].A"></span></td>
                  <td><img :src="`${episodess[1].thumbnail}`" :id="`img_ex${episodess[1].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[1].strValue, episodess[1].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].B"></span></td>
                  <td><img :src="`${episodess[2].thumbnail}`" :id="`img_ex${episodess[2].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[2].strValue, episodess[2].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].C"></span></td>
                  <td><img :src="`${episodess[3].thumbnail}`" :id="`img_ex${episodess[3].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[3].strValue, episodess[3].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].D"></span></td>
                </tr>
              </div>
              </template>
              <!--6번 문제-->
              <template v-else>
              <div style="display:block; width: 700px; margin:0 auto;">
                <tr>
                  <td><img :src="`${episodess[0].thumbnail}`" :id="`img_ex${episodess[0].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[0].strValue, episodess[0].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].A"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[1].thumbnail}`" :id="`img_ex${episodess[1].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[1].strValue, episodess[1].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].B"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[2].thumbnail}`" :id="`img_ex${episodess[2].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[2].strValue, episodess[2].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].C"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[3].thumbnail}`" :id="`img_ex${episodess[3].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[3].strValue, episodess[3].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].D"></span></td>
                </tr>
              </div>
              </template>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button
                v-on:click="playAudio(`https://www.childu.co.kr/PT/NT/data/${questions[intque - 1].Q_mpfile}.mp3`)" class="btn btn-danger">Again</button>
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">Next</button>
            </div>
          </template>
          <template v-if="intque && questions[intque - 1].strQType === 'L5'">
            <table class="time">
              <tr>
                <td width="650">&nbsp;</td>
                 <td width="150">
                  <table width="150" border="0" cellspacing="0" cellpadding="0" style="background:#efefef; border-radius:50px; height:40px; line-height:40px; margin:0 auto;">
                    <tr>

                      <td width="150" align="center" class="style59"><img src="https://childu.co.kr/img/test_clock.png" style="width:26px; position:relative; top:-4px;" /><span id="spanClock" style="font-size:20px; color:#333;margin-left:10px;">{{resTimeData}}</span></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>


            <!--9번, 10번 문제-->
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="question">{{ questions[intque - 1].intQNo }}. <span
                      v-html="questions[intque - 1].strQuestion" class="question"></span></td>
                </tr>
                <tr>
                  <td class="question">{{ questions[intque - 1].intQNo }}. <span
                      v-html="questions[intque - 1].strQuestion2" class="question"></span></td>
                </tr>
                <div style="display:block; width: 700px; margin:0 auto;">
                <tr>
                  <td><img :src="`${episodess[0].thumbnail}`" :id="`img_ex${episodess[0].num}`" style="cursor:pointer"
                      v-on:click="SelExs(intque, episodess[0].strValue, episodess[0].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].A"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[1].thumbnail}`" :id="`img_ex${episodess[1].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[1].strValue, episodess[1].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].B"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[2].thumbnail}`" :id="`img_ex${episodess[2].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[2].strValue, episodess[2].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].C"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[3].thumbnail}`" :id="`img_ex${episodess[3].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[3].strValue, episodess[3].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].D"></span></td>
                </tr>
              </div>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button
                v-on:click="playAudio(`https://www.childu.co.kr/PT/NT/data/${questions[intque - 1].Q_mpfile}.mp3`)" class="btn btn-danger">Again</button>
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">Next</button>
            </div>
          </template>
          <template v-if="intque && questions[intque - 1].strQType === 'L6'">
            <table class="time">
              <tr>
                <td width="650">&nbsp;</td>
                 <td width="150">
                  <table width="150" border="0" cellspacing="0" cellpadding="0" style="background:#efefef; border-radius:50px; height:40px; line-height:40px; margin:0 auto;">
                    <tr>

                      <td width="150" align="center" class="style59"><img src="https://childu.co.kr/img/test_clock.png" style="width:26px; position:relative; top:-4px;" /><span id="spanClock" style="font-size:20px; color:#333;margin-left:10px;">{{resTimeData}}</span></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>


            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="question">{{ questions[intque - 1].intQNo }}. <span
                      v-html="questions[intque - 1].strQuestion" class="question"></span></td>
                </tr>
                <tr>
                  <td colspan="2" class="question">{{ questions[intque - 1].intQNo }}. <span
                      v-html="questions[intque - 1].strQuestion2" class="question"></span></td>
                </tr>
                <div class="v_ans">
                <tr>
                  <td><img :src="`${episodess[0].thumbnail}`" :id="`img_ex${episodess[0].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[0].strValue, episodess[0].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque - 1].A"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[1].thumbnail}`" :id="`img_ex${episodess[1].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[1].strValue, episodess[1].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque - 1].B"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[2].thumbnail}`" :id="`img_ex${episodess[2].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[2].strValue, episodess[2].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque - 1].C"></span></td>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td><img :src="`${episodess[3].thumbnail}`" :id="`img_ex${episodess[3].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[3].strValue, episodess[3].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque - 1].D"></span></td>
                </tr>
              </div>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button
                v-on:click="playAudio(`https://www.childu.co.kr/PT/NT/data/${questions[intque - 1].Q_mpfile}.mp3`)" class="btn btn-danger">Again</button>
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">Next</button>
            </div>
          </template>

          <!--step3 1번 파트 1~3번 문제 영역-->
          <template v-if="intque && questions[intque - 1].strQType === 'L8'">
            <table class="time">
              <tr>
                <td width="650">&nbsp;</td>
                  <td width="150">
                  <table width="150" border="0" cellspacing="0" cellpadding="0" style="background:#efefef; border-radius:50px; height:40px; line-height:40px; margin:0 auto;">
                    <tr>
                                            <td width="150" align="center" class="style59"><img src="https://childu.co.kr/img/test_clock.png" style="width:26px; position:relative; top:-4px;" /><span id="spanClock" style="font-size:20px; color:#333;margin-left:10px;">{{resTimeData}}</span></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="question">{{ questions[intque - 1].intQNo }}. <span
                      v-html="questions[intque - 1].strQuestion" class="question"></span></td>
                </tr>
                <tr>
                  <td colspan="2">
                  <img v-bind:src="`https://www.childu.co.kr/PT/NT/img/${questions[intque - 1].Q_file}.jpg`" style="margin-top: 30px;"></td>
                </tr>
                <div class="v_ans">
                <tr>
                  <td><img :src="`${episodess[0].thumbnail}`" :id="`img_ex${episodess[0].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[0].strValue, episodess[0].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].A"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[1].thumbnail}`" :id="`img_ex${episodess[1].num}`" style="cursor:pointer"
                      v-on:click="SelExs(intque, episodess[1].strValue, episodess[1].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].B"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[2].thumbnail}`" :id="`img_ex${episodess[2].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[2].strValue, episodess[2].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].C"></span></td>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td><img :src="`${episodess[3].thumbnail}`" :id="`img_ex${episodess[3].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[3].strValue, episodess[3].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].D"></span></td>
                </tr>
              </div>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button
                v-on:click="playAudio(`https://www.childu.co.kr/PT/NT/data/${questions[intque - 1].Q_mpfile}.mp3`)" class="btn btn-danger">Again</button>
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">Next</button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'R1'">
            <table class="time">
              <tr>
                <td width="650">&nbsp;</td>
                <td width="150">
                  <table width="150" border="0" cellspacing="0" cellpadding="0" style="background:#efefef; border-radius:50px; height:40px; line-height:40px; margin:0 auto;">
                    <tr>
                                            <td width="150" align="center" class="style59"><img src="https://childu.co.kr/img/test_clock.png" style="width:26px; position:relative; top:-4px;" /><span id="spanClock" style="font-size:20px; color:#333;margin-left:10px;">{{resTimeData}}</span></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
<!-- 테스트 -->
              <table class="table_question">
              <tbody>
                <tr>
                  <td  class="question">{{ questions[intque - 1].intQNo }}. <span
                      v-html="questions[intque - 1].strQuestion" class="question"></span></td>
                </tr>
                <tr>
                  <td style="text-align:center;"><img
                      v-bind:src="`https://www.childu.co.kr/PT/NT/img/${questions[intque - 1].Q_file}.jpg`" style="padding: 20px 0; margin-top: 30px;"></td>
                </tr>
                <template v-if="questions[intque - 1].vhType === 'h'">

                <tr>
                <!--2번째 파트 1번 문제 영역-->
                  <td>
                    <table style="width:800px; margin: 0 auto; text-align:center;">
                      <tr>
                        <td><img :src="`${episodess[0].thumbnail}`" :id="`img_ex${episodess[0].num}`" style="cursor:pointer;"
                            v-on:click="SelExs(intque, episodess[0].strValue, episodess[0].num, 4)" /></td>
                        <td class="style38" style="text-align:center;" ><span v-html="questions[intque - 1].A"></span></td>
                        <td><img :src="`${episodess[1].thumbnail}`" :id="`img_ex${episodess[1].num}`" style="cursor:pointer;"
                            v-on:click="SelExs(intque, episodess[1].strValue, episodess[1].num, 4)" /></td>
                        <td class="style38" style="text-align:center;"><span v-html="questions[intque - 1].B"></span></td>
                        <td><img :src="`${episodess[2].thumbnail}`" :id="`img_ex${episodess[2].num}`" style="cursor:pointer;"
                            v-on:click="SelExs(intque, episodess[2].strValue, episodess[2].num, 4)" /></td>
                        <td class="style38" style="text-align:center;"><span v-html="questions[intque - 1].C"></span></td>
                        <td><img :src="`${episodess[3].thumbnail}`" :id="`img_ex${episodess[3].num}`" style="cursor:pointer;"
                            v-on:click="SelExs(intque, episodess[3].strValue, episodess[3].num, 4)" /></td>
                        <td class="style38" style="text-align:center;"><span v-html="questions[intque - 1].D"></span></td>
                        </tr>
                      </table>


                    </td>
                </tr>
                </template>
                <!--2번째 파트 3, 4번 문제 영역-->
                <template v-else>
                <div class="v_ans">
                <tr>
                  <td><img :src="`${episodess[0].thumbnail}`" :id="`img_ex${episodess[0].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[0].strValue, episodess[0].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].A"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[1].thumbnail}`" :id="`img_ex${episodess[1].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[1].strValue, episodess[1].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].B"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[2].thumbnail}`" :id="`img_ex${episodess[2].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[2].strValue, episodess[2].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].C"></span></td>
                </tr>

                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td><img :src="`${episodess[3].thumbnail}`" :id="`img_ex${episodess[3].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[3].strValue, episodess[3].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].D"></span></td>
                </tr>
              </div>
              </template>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">Next</button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'R2'">
            <table class="time">
              <tr>
                <td width="650">&nbsp;</td>
               <td width="150">
                  <table width="150" border="0" cellspacing="0" cellpadding="0" style="background:#efefef; border-radius:50px; height:40px; line-height:40px; margin:0 auto;">
                    <tr>
                                            <td width="150" align="center" class="style59"><img src="https://childu.co.kr/img/test_clock.png" style="width:26px; position:relative; top:-4px;" /><span id="spanClock" style="font-size:20px; color:#333;margin-left:10px;">{{resTimeData}}</span></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <!--2번째 파트 5번 문제 영역-->
            <table class="table_question" >
              <tbody>
                <tr>
                <td colspan="4" class="question">{{ questions[intque - 1].intQNo }}. <span
                      v-html="questions[intque - 1].strQuestion" class="question"></span></td>
                </tr>
                <tr>
                  <td colspan="4" style="text-align:center;"><img
                      v-bind:src="`https://www.childu.co.kr/PT/NT/img/${questions[intque - 1].Q_file}.jpg`" style="margin-top: 30px;"></td>
                </tr>
                <tr>
                    <td class="answer" v-for="(episode, i) in episodess" :key="i" style="text-align:center;"><img :src="episode.thumbnail"
                      v-on:click="SelExs(intque, episode.strValue, episode.num, 4)" /></td>
                </tr>
                <tr>
                  <td width="300" style="text-align:center;"><img
                      v-bind:src="`https://www.childu.co.kr/PT/NT/img/${questions[intque - 1].A_file}.jpg`"></td>
                  <td width="300" style="text-align:center;"><img
                      v-bind:src="`https://www.childu.co.kr/PT/NT/img/${questions[intque - 1].B_file}.jpg`"></td>
                  <td width="300" style="text-align:center;"><img
                      v-bind:src="`https://www.childu.co.kr/PT/NT/img/${questions[intque - 1].C_file}.jpg`"></td>
                  <td width="300" style="text-align:center;"><img
                      v-bind:src="`https://www.childu.co.kr/PT/NT/img/${questions[intque - 1].D_file}.jpg`"></td>
                </tr>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">Next</button>
            </div>
          </template>
          <template v-if="intque && questions[intque - 1].strQType === 'R3'">
            <table class="time">
              <tr>
                <td width="650">&nbsp;</td>
               <td width="150">
                  <table width="150" border="0" cellspacing="0" cellpadding="0" style="background:#efefef; border-radius:50px; height:40px; line-height:40px; margin:0 auto;">
                    <tr>
                                            <td width="150" align="center" class="style59"><img src="https://childu.co.kr/img/test_clock.png" style="width:26px; position:relative; top:-4px;" /><span id="spanClock" style="font-size:20px; color:#333;margin-left:10px;">{{resTimeData}}</span></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <!--2번째 파트 9,10번 문제 영역-->
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="question"><span v-html="questions[intque - 1].strQuestion"></span></td>
                </tr>
                <tr>
                  <td colspan="2" class="style37"><span
                      v-html="questions[intque - 1].strQuestion2"></span></td>
                </tr>
              </tbody>
            </table>
            <table class="table_question">
              <tbody>
                <div class="v_ans">
                <tr>
                  <td colspan="2" class="question" style="text-align:left;">{{ questions[intque - 1].intQNo }}. <span
                      v-html="questions[intque - 1].strQuestion3" class="question"></span></td>
                </tr>
                <tr>
                  <td ><img :src="`${episodess[0].thumbnail}`" :id="`img_ex${episodess[0].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[0].strValue, episodess[0].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque - 1].A"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[1].thumbnail}`" :id="`img_ex${episodess[1].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[1].strValue, episodess[1].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque - 1].B"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[2].thumbnail}`" :id="`img_ex${episodess[2].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[2].strValue, episodess[2].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque - 1].C"></span></td>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td><img :src="`${episodess[3].thumbnail}`" :id="`img_ex${episodess[3].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[3].strValue, episodess[3].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque - 1].D"></span></td>
                </tr>
              </div>
              </tbody>
            </table>
            <br/>
            <table class="table_question">
              <tbody>
                <div class="v_ans">
                <tr>
                  <td colspan="2" class="question" style="text-align:left;">{{ questions[intque].intQNo }}. <span
                      v-html="questions[intque].strQuestion3" class="question"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess2[0].thumbnail}`" :id="`img_ex2${episodess2[0].num}`"
                      style="cursor:pointer;"
                      v-on:click="SelExs2(intque + 1, episodess2[0].strValue, episodess2[0].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque].A"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess2[1].thumbnail}`" :id="`img_ex2${episodess2[1].num}`"
                      style="cursor:pointer;"
                      v-on:click="SelExs2(intque + 1, episodess2[1].strValue, episodess2[1].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque].B"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess2[2].thumbnail}`" :id="`img_ex2${episodess2[2].num}`"
                      style="cursor:pointer;"
                      v-on:click="SelExs2(intque + 1, episodess2[2].strValue, episodess2[2].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque].C"></span></td>
                </tr>
                <tr v-if="questions[intque].intEcnt != '3'">
                  <td><img :src="`${episodess2[3].thumbnail}`" :id="`img_ex2${episodess2[3].num}`"
                      style="cursor:pointer;"
                      v-on:click="SelExs2(intque + 1, episodess2[3].strValue, episodess2[3].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque].D"></span></td>
                </tr>
                </div>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 2)" class="btn btn-primary">Next</button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'R4'">
            <table class="time">
              <tr>
                <td width="650">&nbsp;</td>
                <td width="150">
                  <table width="150" border="0" cellspacing="0" cellpadding="0" style="background:#efefef; border-radius:50px; height:40px; line-height:40px; margin:0 auto;">
                    <tr>
                                            <td width="150" align="center" class="style59"><img src="https://childu.co.kr/img/test_clock.png" style="width:26px; position:relative; top:-4px;" /><span id="spanClock" style="font-size:20px; color:#333;margin-left:10px;">{{resTimeData}}</span></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="question"><span v-html="questions[intque - 1].strQuestion" class="question"></span></td>
                </tr>
                <tr>
                  <td colspan="2" class="style37"><span
                      v-html="questions[intque - 1].strQuestion2"></span></td>
                </tr>
              </tbody>
            </table>

            <!--step3 2번 파트, 8~10번 문제 영역-->
            <table class="table_question">
              <tbody>
              <div class="v_ans">
                <tr>
                  <td colspan="2" class="question" style="text-align:center;">{{ questions[intque - 1].intQNo }}. <span
                      v-html="questions[intque - 1].strQuestion3"  class="question"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[0].thumbnail}`" :id="`img_ex${episodess[0].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[0].strValue, episodess[0].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque - 1].A"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[1].thumbnail}`" :id="`img_ex${episodess[1].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[1].strValue, episodess[1].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque - 1].B"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[2].thumbnail}`" :id="`img_ex${episodess[2].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[2].strValue, episodess[2].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque - 1].C"></span></td>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td><img :src="`${episodess[3].thumbnail}`" :id="`img_ex${episodess[3].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[3].strValue, episodess[3].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque - 1].D"></span></td>
                </tr>
              </div>
              </tbody>
            </table>
            <table class="table_question">
              <tbody>
              <div class="v_ans">
                <tr>
                  <td colspan="2"  class="question" style="text-align:center;">{{ questions[intque].intQNo }}. <span
                      v-html="questions[intque].strQuestion3"  class="question"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess2[0].thumbnail}`" :id="`img_ex2${episodess2[0].num}`"
                      style="cursor:pointer;"
                      v-on:click="SelExs2(intque + 1, episodess2[0].strValue, episodess2[0].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque].A"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess2[1].thumbnail}`" :id="`img_ex2${episodess2[1].num}`"
                      style="cursor:pointer;"
                      v-on:click="SelExs2(intque + 1, episodess2[1].strValue, episodess2[1].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque].B"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess2[2].thumbnail}`" :id="`img_ex2${episodess2[2].num}`"
                      style="cursor:pointer;"
                      v-on:click="SelExs2(intque + 1, episodess2[2].strValue, episodess2[2].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque].C"></span></td>
                </tr>
                <tr v-if="questions[intque].intEcnt != '3'">
                  <td><img :src="`${episodess2[3].thumbnail}`" :id="`img_ex2${episodess2[3].num}`"
                      style="cursor:pointer;"
                      v-on:click="SelExs2(intque + 1, episodess2[3].strValue, episodess2[3].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque].D"></span></td>
                </tr>
              </div>
              </tbody>
            </table>
            <table class="table_question">
              <tbody>
              <div class="v_ans">
                <tr>
                  <td colspan="2"  class="question" style="text-align:left;">{{ questions[intque + 1].intQNo }}. <span
                      v-html="questions[intque + 1].strQuestion3"  class="question"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess3[0].thumbnail}`" :id="`img_ex2${episodess3[0].num}`"
                    style="cursor:pointer;"
                      v-on:click="SelExs3(intque + 2, episodess3[0].strValue, episodess3[0].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque + 1].A"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess3[1].thumbnail}`" :id="`img_ex2${episodess3[1].num}`"
                      style="cursor:pointer;"
                      v-on:click="SelExs3(intque + 2, episodess3[1].strValue, episodess3[1].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque + 1].B"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess3[2].thumbnail}`" :id="`img_ex2${episodess3[2].num}`"
                      style="cursor:pointer;"
                      v-on:click="SelExs3(intque + 2, episodess3[2].strValue, episodess3[2].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque + 1].C"></span></td>
                </tr>
                <tr v-if="questions[intque + 1].intEcnt != '3'">
                  <td><img :src="`${episodess3[3].thumbnail}`" :id="`img_ex2${episodess3[3].num}`"
                      style="cursor:pointer;"
                      v-on:click="SelExs3(intque + 2, episodess3[3].strValue, episodess3[3].num, 4)" /></td>
                  <td class="style38" width="600"><span v-html="questions[intque + 1].D"></span></td>
                </tr>
              </div>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 3)" class="btn btn-primary">Next</button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'S1'">
            <table class="time">
              <tr>
                <td width="650">&nbsp;</td>
                <td width="150">
                  <table width="150" border="0" cellspacing="0" cellpadding="0" style="background:#efefef; border-radius:50px; height:40px; line-height:40px; margin:0 auto;">
                    <tr>
                                            <td width="150" align="center" class="style59"><img src="https://childu.co.kr/img/test_clock.png" style="width:26px; position:relative; top:-4px;" /><span id="spanClock" style="font-size:20px; color:#333;margin-left:10px;">{{resTimeData}}</span></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <!--3번째 파트 문제 영역-->
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="2" class="question">{{ questions[intque - 1].intQNo }}. <span
                      v-html="questions[intque - 1].strQuestion"></span></td>
                </tr>
                <tr>
                  <td colspan="2"><img
                      v-bind:src="`https://www.childu.co.kr/PT/NT/img/${questions[intque - 1].Q_file}.jpg`" style="margin-top: 30px;"></td>
                </tr>
                <div class="v_ans">
                <tr>
                  <td><img :src="`${episodess[0].thumbnail}`" :id="`img_ex${episodess[0].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[0].strValue, episodess[0].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].A"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[1].thumbnail}`" :id="`img_ex${episodess[1].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[1].strValue, episodess[1].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].B"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[2].thumbnail}`" :id="`img_ex${episodess[2].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[2].strValue, episodess[2].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].C"></span></td>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td><img :src="`${episodess[3].thumbnail}`" :id="`img_ex${episodess[3].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[3].strValue, episodess[3].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].D"></span></td>
                </tr>
              </div>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">Next</button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'W1'">
            <table class="time">
              <tr>
                <td width="650">&nbsp;</td>
                <td width="150">
                  <table width="150" border="0" cellspacing="0" cellpadding="0" style="background:#efefef; border-radius:50px; height:40px; line-height:40px; margin:0 auto;">
                    <tr>
                                            <td width="150" align="center" class="style59"><img src="https://childu.co.kr/img/test_clock.png" style="width:26px; position:relative; top:-4px;" /><span id="spanClock" style="font-size:20px; color:#333;margin-left:10px;">{{resTimeData}}</span></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <!--4번째 파트 1,2번 문제 영역-->
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="8" class="question">{{ questions[intque - 1].intQNo }}. <span
                      v-html="questions[intque - 1].strQuestion"></span></td>
                </tr>
                <tr>
                  <td colspan="8"><img
                      v-bind:src="`https://www.childu.co.kr/PT/NT/img/${questions[intque - 1].Q_file}.jpg`" style="margin-top: 30px;"></td>
                </tr>
                <template v-if="questions[intque - 1].vhType === 'h'">
                <div class="v_ans">
                <tr>
                  <td><img :src="`${episodess[0].thumbnail}`" :id="`img_ex${episodess[0].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[0].strValue, episodess[0].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].A"></span></td>
                  <td><img :src="`${episodess[1].thumbnail}`" :id="`img_ex${episodess[1].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[1].strValue, episodess[1].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].B"></span></td>
                  <td><img :src="`${episodess[2].thumbnail}`" :id="`img_ex${episodess[2].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[2].strValue, episodess[2].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].C"></span></td>
                  <td><img :src="`${episodess[3].thumbnail}`" :id="`img_ex${episodess[3].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[3].strValue, episodess[3].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].D"></span></td>
                </tr>
                </div>
                </template>

                <!--4번째 파트 3~5번 문제 영역-->
                <template v-else>
                <div class="v_ans" style="display:block; width: 500px!important;">
                <tr>
                  <td><img :src="`${episodess[0].thumbnail}`" :id="`img_ex${episodess[0].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[0].strValue, episodess[0].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].A"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[1].thumbnail}`" :id="`img_ex${episodess[1].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[1].strValue, episodess[1].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].B"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[2].thumbnail}`" :id="`img_ex${episodess[2].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[2].strValue, episodess[2].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].C"></span></td>
                </tr>
                <tr v-if="questions[intque - 1].intEcnt != '3'">
                  <td><img :src="`${episodess[3].thumbnail}`" :id="`img_ex${episodess[3].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[3].strValue, episodess[3].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].D"></span></td>
                </tr>
              </div>
              </template>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">Next</button>
            </div>
          </template>

          <template v-if="intque && questions[intque - 1].strQType === 'W2'">
            <table class="time">
              <tr>
                <td width="650">&nbsp;</td>
                <td width="150">
                  <table width="150" border="0" cellspacing="0" cellpadding="0" style="background:#efefef; border-radius:50px; height:40px; line-height:40px; margin:0 auto;">
                    <tr>
                                            <td width="150" align="center" class="style59"><img src="https://childu.co.kr/img/test_clock.png" style="width:26px; position:relative; top:-4px;" /><span id="spanClock" style="font-size:20px; color:#333;margin-left:10px;">{{resTimeData}}</span></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <!--step3 3번 파트, 1~2번-->
            <table class="table_question">
              <tbody>
                <tr>
                  <td colspan="3" class="question">{{ questions[intque - 1].intQNo }}. <span
                      v-html="questions[intque - 1].strQuestion"></span></td>
                </tr>
                <div class="v_ans" style="margin-top: 50px;">
                <tr>
                  <td><img :src="`${episodess[0].thumbnail}`" :id="`img_ex${episodess[0].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[0].strValue, episodess[0].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].A"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[1].thumbnail}`" :id="`img_ex${episodess[1].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[1].strValue, episodess[1].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].B"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[2].thumbnail}`" :id="`img_ex${episodess[2].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[2].strValue, episodess[2].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].C"></span></td>
                </tr>
                <tr>
                  <td><img :src="`${episodess[3].thumbnail}`" :id="`img_ex${episodess[3].num}`" style="cursor:pointer;"
                      v-on:click="SelExs(intque, episodess[3].strValue, episodess[3].num, 4)" /></td>
                  <td class="style38"><span v-html="questions[intque - 1].D"></span></td>
                </tr>
                </div>
              </tbody>
            </table>
            <!-- Again Next 영역 -->
            <div class="btn_box">
              <button class="btn btn-danger"
                v-on:click="playAudio(`https://www.childu.co.kr/PT/NT/data/${questions[intque - 1].Q_mpfile}.mp3`)">Again</button>
              <button v-on:click="nextTest(intque, 1)" class="btn btn-primary">Next</button>
            </div>
          </template>

        </template>
      </tbody>
    </table>
  </div>
  <div style="position:absolute; bottom:0;">

  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    data: () => ({
      questions: [],
      answer: [],
      result: [],
      audio: [],
      intque: null,
      timeCounter: 2400, //3분
      resTimeData: '',
      strState: 'Start',
      episodes: [{
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num1.png',
        strValue: 'A',
        num: '1'
      }, {
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num2.png',
        strValue: 'B',
        num: '2'
      }, {
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num3.png',
        strValue: 'C',
        num: '3'
      }],
      episodess: [{
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num1.png',
        strValue: 'A',
        num: '1'
      }, {
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num2.png',
        strValue: 'B',
        num: '2'
      }, {
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num3.png',
        strValue: 'C',
        num: '3'
      }, {
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num4.png',
        strValue: 'D',
        num: '4'
      }],
      episodess2: [{
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num1.png',
        strValue: 'A',
        num: '1'
      }, {
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num2.png',
        strValue: 'B',
        num: '2'
      }, {
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num3.png',
        strValue: 'C',
        num: '3'
      }, {
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num4.png',
        strValue: 'D',
        num: '4'
      }],
      episodess3: [{
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num1.png',
        strValue: 'A',
        num: '1'
      }, {
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num2.png',
        strValue: 'B',
        num: '2'
      }, {
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num3.png',
        strValue: 'C',
        num: '3'
      }, {
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num4.png',
        strValue: 'D',
        num: '4'
      }],
      episodess4: [{
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num1.png',
        strValue: 'A',
        num: '1'
      }, {
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num2.png',
        strValue: 'B',
        num: '2'
      }, {
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num3.png',
        strValue: 'C',
        num: '3'
      }, {
        thumbnail: 'https://childu.co.kr/PT/NT/images/Num4.png',
        strValue: 'D',
        num: '4'
      }]
    }),
    mounted() {
      axios.get(this.$root.baseUrl + '/PlacementTest/' + this.$route.params.level).then((response) => {
        if (response.data.error) {
          alert("레벨테스트를 불러올 수 없습니다.\n" + response.data.error)
        } else {
          this.questions = response.data.result;
          this.intque = 0;
          //this.Timerstart();
        }
      })
    },
    methods: {
      playAudio(url) {
        (new Audio(url)).play()
      },
      nextTest(intNum, snum) {
        //console.log(intNum);
        if (this.audio[intNum - 1]) {
          this.audio[intNum - 1].pause();
        }
        this.strState = '';
        for (var i = 0; i < snum; i++) {
          //console.log(intNum + i);
          if (!this.answer[intNum + i]) {
            console.log(intNum + i);
            $.notify({
              // options
              message: '문제에 답을 선택하세요. '
            }, {
              // settings
              type: 'danger',
              z_index: 9999
            });
            return;
          }
        }

        if (this.intque == 10) {
          if (this.$route.params
            .level == '1' || this.$route.params.level == '2' || this.$route.params.level == '3') {
            this.strState = 'LEnd';
          } else {
            this.intque = intNum + snum;
          }
        } else if (this.intque == 18) {
          if (this.$route.params.level == '3') {
            this.strState = 'REnd';
            this.intque = 20;
          } else {
            this.intque = intNum + snum;
          }
        } else if (this.intque == 19) {
            this.intque = 20;
            this.strState = 'REnd';
        } else if (this.intque == 25) {
          if (this.$route.params.level == '1' || this.$route.params.level == '2' || this.$route.params.level == '3') {
            this.strState = 'SEnd';
          } else {
            this.intque = intNum + snum;
          }
        } else if (this.intque == 30) {
          if (this.$route.params.level == '1' || this.$route.params.level == '2' || this.$route.params.level == '3') {
            this.strState = 'ALLEnd';
          } else {
            this.intque = intNum + snum;
          }
        }else {
          this.intque = intNum + snum;
          if (this.questions[intNum].Q_mpfile) {
            this.audio[intNum] = new Audio("https://www.childu.co.kr/PT/NT/data/" + this.questions[intNum].Q_mpfile +
              ".mp3");
            this.audio[intNum].play();
            //console.log(this.audio[intNum]);
          }
          //console.log(intNum);
          console.log(this.questions[intNum].strQType);
          //console.log(this.questions[intNum].vhType);
        }

        for (var i = 1; i <= 3; i++) {
          this.episodes[i - 1].thumbnail = 'https://childu.co.kr/PT/NT/images/Num' + i + '.png';
          this.episodess[i - 1].thumbnail = 'https://childu.co.kr/PT/NT/images/Num' + i + '.png';
          this.episodess2[i - 1].thumbnail = 'https://childu.co.kr/PT/NT/images/Num' + i + '.png';
          this.episodess3[i - 1].thumbnail = 'https://childu.co.kr/PT/NT/images/Num' + i + '.png';
          this.episodess4[i - 1].thumbnail = 'https://childu.co.kr/PT/NT/images/Num' + i + '.png';
        }
        this.episodess[3].thumbnail = 'https://childu.co.kr/PT/NT/images/Num4.png';
        this.episodess2[3].thumbnail = 'https://childu.co.kr/PT/NT/images/Num4.png';
        this.episodess3[3].thumbnail = 'https://childu.co.kr/PT/NT/images/Num4.png';
        this.episodess4[3].thumbnail = 'https://childu.co.kr/PT/NT/images/Num4.png';
      },
      nextLStart(intNum) {
        this.intque++;
        this.audio[intNum] = new Audio("https://childu.co.kr/PT/NT/data/" + this.questions[intNum].Q_mpfile +
          ".mp3");
        this.audio[intNum].play();
        clearInterval(this.polling);
        this.timeCounter = 600;
        this.startTime();
        console.log(this.questions[intNum].strQType);
        //this.playAudio("https://www.childu.co.kr/PT/NT/data/" + this.questions[intNum].Q_mpfile + ".mp3");
      },
      nextLEnd() {
        this.strState = 'RStart';
        clearInterval(this.polling);
        this.timeCounter = 600;
      },
      nextRStart(intNum) {
        this.strState = '';
        this.intque++;
        this.startTime();
        console.log(this.questions[intNum].strQType);
        //console.log(this.$root.user);
      },
      nextREnd() {
        this.strState = 'SStart';
        clearInterval(this.polling);
        this.timeCounter = 600;
      },
      nextSStart(intNum) {
        this.strState = '';
        this.intque++;
        this.startTime();
        console.log(this.questions[intNum].strQType);
        //console.log(this.$root.user);
      },
      nextSEnd() {
        this.strState = 'WStart';
        clearInterval(this.polling);
        this.timeCounter = 600;
      },
      nextWStart(intNum) {
        this.strState = '';
        this.intque++;
        this.startTime();
        console.log(this.questions[intNum].strQType);
        //console.log(this.$root.user);
      },
      goLStep() {
        var today = new Date();
        var year = today.getFullYear();
        var month = ('0' + (today.getMonth() + 1)).slice(-2);
        var day = ('0' + today.getDate()).slice(-2);
        var hours = ('0' + today.getHours()).slice(-2);
        var minutes = ('0' + today.getMinutes()).slice(-2);
        var seconds = ('0' + today.getSeconds()).slice(-2);

        var dateString = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;

        this.answer[0] = dateString;

        if (this.$route.params.level == '3' || this.$route.params.level ==
          '1' || this.$route.params.level == '2') {
          this.strState = 'LStart';
        } else {
          this.strState = '';
          this.intque++;
          this.timeCounter = 2400;
          this.startTime();
          console.log(this.questions[0].strQType);
        }
      },
      nextEnd() {
        //console.log(this.$root.user);

        clearInterval(this.polling);

        let form = new FormData()
        form.append('answer', this.answer)
        form.append('level', this.$route.params.level)
        if (!this.$route.params.idx) {
          form.append('user_idx', this.$root.user.id)
          form.append('user_createdAt', this.$root.user.createdAt)
          form.append('user_business', this.$root.user.business)
          form.append('user_id', this.$root.user.username)
          form.append('user_name', this.$root.user.name)
        } else {
          form.append('testIdx', this.$route.params.idx)
        }
        axios.post(this.$root.baseUrl + '/PlacementTest/Result', form).then(response => {
          return new Promise((resolve) => {
            if (response.data.error) {
              alert("처리 중 오류가 발생했습니다.\n" + response.data.error)
            } else {
              //console.log(response.data);
              this.strState = 'sEnd';
              this.result = response.data.result;

            }
          })
        })
      },
      SelEx(intNum, strValue, strNum, MaxNum) {
        this.answer[intNum] = strValue;

        //console.log(this.answer[intNum]);
        for (var i = 1; i <= MaxNum; i++) {
          if (strNum == i) {
            this.episodes[i - 1].thumbnail = 'https://childu.co.kr/PT/NT/images/Num2' + i + '.png';
          } else {
            this.episodes[i - 1].thumbnail = 'https://childu.co.kr/PT/NT/images/Num' + i + '.png';
          }
        }
      },
      SelExs(intNum, strValue, strNum, MaxNum) {
        this.answer[intNum] = strValue;

        for (var i = 1; i <= MaxNum; i++) {
          if (strNum == i) {
            this.episodess[i - 1].thumbnail = 'https://childu.co.kr/PT/NT/images/Num2' + i + '.png';
          } else {
            this.episodess[i - 1].thumbnail = 'https://childu.co.kr/PT/NT/images/Num' + i + '.png';
          }
        }
      },
      SelExs2(intNum, strValue, strNum, MaxNum) {
        this.answer[intNum] = strValue;

        for (var i = 1; i <= MaxNum; i++) {
          if (strNum == i) {
            this.episodess2[i - 1].thumbnail = 'https://childu.co.kr/PT/NT/images/Num2' + i + '.png';
          } else {
            this.episodess2[i - 1].thumbnail = 'https://childu.co.kr/PT/NT/images/Num' + i + '.png';
          }
        }
      },
      SelExs3(intNum, strValue, strNum, MaxNum) {
        this.answer[intNum] = strValue;

        for (var i = 1; i <= MaxNum; i++) {
          if (strNum == i) {
            this.episodess3[i - 1].thumbnail = 'https://childu.co.kr/PT/NT/images/Num2' + i + '.png';
          } else {
            this.episodess3[i - 1].thumbnail = 'https://childu.co.kr/PT/NT/images/Num' + i + '.png';
          }
        }
      },
      SelExs4(intNum, strValue, strNum, MaxNum) {
        this.answer[intNum] = strValue;

        for (var i = 1; i <= MaxNum; i++) {
          if (strNum == i) {
            this.episodess4[i - 1].thumbnail = 'https://childu.co.kr/PT/NT/images/Num2' + i + '.png';
          } else {
            this.episodess4[i - 1].thumbnail = 'https://childu.co.kr/PT/NT/images/Num' + i + '.png';
          }
        }
      },
      startTime() {
        // 1초에 한번씩 start 호출
        this.polling = setInterval(() => {
          this.timeCounter--; //1찍 감소
          this.resTimeData = this.prettyTime();
          //console.log(this.resTimeData);
          if (this.timeCounter <= 0) this.timeStop()
        }, 1000)
      },
      // 시간 형식으로 변환 리턴
      prettyTime() {
        let time = this.timeCounter / 60
        let minutes = parseInt(time)
        let secondes = Math.round((time - minutes) * 60)
        return this.pad(minutes, 2) + ":" + this.pad(secondes, 2)
      },
      // 2자리수로 만들어줌 09,08...
      pad(n, width) {
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n
      },
      timeStop() {
        clearInterval(this.polling)
      }
    }
  }
</script>
